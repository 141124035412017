import { useMemo } from "react";
import { isEmpty } from "lodash";
import { useFirestoreCollection } from "./useFirestoreCollection";
import { useOverviewPhotos } from "./useOverviewPhotos";

export const useObjectPhotoCount = (projectId: string, objectId: string) => {
  const [photos, isLoadingPhotos] = useFirestoreCollection(
    ["projects", projectId, "objects", objectId, "photos"].join("/")
  );

  const [overviewPhotos, isLoadingOverviewPhotos] = useOverviewPhotos(
    projectId,
    objectId
  );

  const [photosUploaded, photosTotal] = useMemo(() => {
    const photosUploaded = photos.filter((p) => !isEmpty(p.get("url"))).length;
    const photosTotal = photos.length;

    return [photosUploaded, photosTotal] as const;
  }, [photos]);

  const [overviewPhotosUploaded, overviewPhotosTotal] = useMemo(() => {
    const photosUploaded = overviewPhotos.filter(
      (p) => !isEmpty(p.get("url"))
    ).length;
    const photosTotal = overviewPhotos.length;

    return [photosUploaded, photosTotal] as const;
  }, [overviewPhotos]);

  const isLoading = isLoadingPhotos || isLoadingOverviewPhotos;

  return {
    photosUploaded: photosUploaded + overviewPhotosUploaded,
    photosTotal: photosTotal + overviewPhotosTotal,
    isLoading,
  };
};

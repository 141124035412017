import { useCallback, useMemo } from "react";
import { isEmpty, orderBy } from "lodash";
import { useFirestoreCollection } from "./useFirestoreCollection";
import {
  addDoc,
  collection,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../App.web";

export type AreaLevelLookup = Map<string, number>;

export type UpdateAreaSorting = {
  doc: QueryDocumentSnapshot<DocumentData>;
  order: number;
}[];

export const useAreas = (projectId: string, objectId: string) => {
  const [unsortedAreas, isLoading] = useFirestoreCollection(
    ["projects", projectId, "objects", objectId, "areas"].join("/")
  );

  const areas = useMemo(
    () => orderBy(unsortedAreas, [(a) => a.get("order")], ["asc"]),
    [unsortedAreas]
  );

  const levelForAreaId = useMemo(() => {
    const lookup: AreaLevelLookup = new Map();

    const rootElements = areas.filter((a) => a.get("parentId") == null);

    const assignLevel = (currentAreas: typeof areas, level: number) => {
      currentAreas.forEach((a) => {
        lookup.set(a.id, level);

        const children = areas.filter((x) => x.get("parentId") === a.id);
        assignLevel(children, level + 1);
      });
    };

    assignLevel(rootElements, 1);

    return lookup;
  }, [areas]);

  const addArea = useCallback(
    async (areaName: string, parentId: string | null, order?: number) => {
      if (isEmpty(areaName)) {
        return;
      }

      const collectionPath = [
        "projects",
        projectId,
        "objects",
        objectId,
        "areas",
      ].join("/");

      if (!order) {
        const q = query(
          collection(firestore, collectionPath),
          where("parentId", "==", parentId)
        );

        const existingAreas = await getDocs(q);
        order = existingAreas.size + 1;
      }

      return addDoc(collection(firestore, collectionPath), {
        title: areaName,
        parentId,
        order,
        createdAt: Timestamp.now(),
      });
    },
    [projectId, objectId]
  );

  const rootAreas = useMemo(
    () => areas.filter((a) => levelForAreaId.get(a.id) === 1),
    [levelForAreaId]
  );

  const updateAreaSorting = useCallback(
    async (updatedOrders: UpdateAreaSorting) => {
      for await (const updatedOrder of updatedOrders) {
        const { doc, order } = updatedOrder;

        await updateDoc(doc.ref, { order });
      }
    },
    []
  );

  return {
    areas,
    addArea,
    rootAreas,
    levelForAreaId,
    isLoading,
    updateAreaSorting,
  };
};

import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Stack } from "native-base";
import { useObjectWebAccessAccounts } from "../../hooks/useObjectWebAccessAccounts.web";
import TextFormInput from "../form/TextFormInput.web";
import CreateWebAccess from "../web/CreateWebAccess";
import ObjectWebAccessItems from "./ObjectWebAccessItems";
import WebAccessCode from "./WebAccessCode";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function ObjectWebAccess({ object, updateObject }: Props) {
  const objectId = object.id;
  const projectId = object.ref.parent.parent!.id;

  const [accounts] = useObjectWebAccessAccounts(projectId, objectId);

  return (
    <Stack space={4}>
      <TextFormInput
        label="Objekt ID"
        fieldPath="webAccessId"
        doc={object}
        updateDoc={updateObject}
      />

      <WebAccessCode webAccessId={object.get("webAccessId")} />

      <ObjectWebAccessItems
        webAccessAccounts={accounts}
        webAccessId={object?.get("webAccessId") ?? ""}
      />

      <CreateWebAccess projectId={projectId} objectId={objectId} />
    </Stack>
  );
}

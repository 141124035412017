import React from "react";
import { HStack, Stack } from "native-base";
import { InterfaceHStackProps } from "native-base/lib/typescript/components/primitives/Stack/HStack";

type Props = {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
} & InterfaceHStackProps;

export default function TwoCols({ leftContent, rightContent, ...rest }: Props) {
  return (
    <HStack space={4} {...rest}>
      <Stack flex={1} space={2}>
        {leftContent}
      </Stack>

      <Stack flex={1} space={2}>
        {rightContent}
      </Stack>
    </HStack>
  );
}

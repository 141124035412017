import React from "react";
import { Box, Image, Stack } from "native-base";
import NavigationItem from "./components/NavigationItem";
import VermflexLogo from "../../assets/vermflex-logo.png";

export default function Navigation() {
  return (
    <Box w="300px" bgColor="gray.50" p={5}>
      <Image
        source={{ uri: VermflexLogo }}
        w="full"
        h={8}
        mt={4}
        mb={10}
        resizeMode="contain"
        alt="vermflex"
      />

      <nav>
        <Stack space={2}>
          <NavigationItem to="/admin/projects">Aufträge</NavigationItem>
          <NavigationItem to="/admin/users">Benutzer</NavigationItem>
          <NavigationItem to="/admin/profile">Account</NavigationItem>
        </Stack>
      </nav>
    </Box>
  );
}

import { useCallback, useContext } from "react";
import { CurrentUserContext } from "../App.web";

export const useUserIDToken = () => {
  const currentUser = useContext(CurrentUserContext);

  return useCallback(async () => {
    if (!currentUser) {
      return;
    }

    const idToken = await currentUser.getIdToken();

    return idToken;
  }, [currentUser]);
};

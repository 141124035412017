import { useFirebaseObject } from "./useFirebaseObject";

export type ObjectType = "familyHome" | "apartmentHouse" | "street";

export const useObjectType = (projectId: string, objectId: string) => {
  const [object] = useFirebaseObject(
    ["projects", projectId, "objects", objectId].join("/")
  );

  if (!object) {
    return undefined;
  }

  return (object as any).get("objectType") as ObjectType | null;
};

import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { useObjectAppointments } from "./useObjectAppointments";
import { StateColor } from "./useProjectState";

export const useObjectState = (
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>
) => {
  const [appointments, , isLoadingAppointments] = useObjectAppointments(
    object.ref.parent.parent!.id,
    object.id
  );

  const data = object?.data();

  if (!data || isLoadingAppointments) {
    return {};
  }

  const {
    sentToOwnerAt,
    returnedFromOwnerAt,
    sentToClientAt,
    returnedFromClientAt,
    sentFinalToOwnerAt,
    protocolCreatedAt,
    manualClosureAt,
  } = data;

  const confirmedAppointmentCount = appointments.filter(
    (a) => a.get("state") === "confirmed"
  ).length;
  const appointmentCount = appointments.length;

  let stateText = undefined;
  let stateColor: StateColor | undefined = undefined;

  if (sentFinalToOwnerAt || manualClosureAt) {
    stateText = "Abgeschlossen";
    stateColor = "green";
  } else if (returnedFromClientAt) {
    stateText = "Von Auftraggeber zurück";
    stateColor = "yellow";
  } else if (sentToClientAt) {
    stateText = "An Auftraggeber verschickt";
    stateColor = "yellow";
  } else if (returnedFromOwnerAt) {
    stateText = "Von Eigentümer zurück";
    stateColor = "yellow";
  } else if (sentToOwnerAt) {
    stateText = "An Eigentümer verschickt";
    stateColor = "yellow";
  } else if (protocolCreatedAt) {
    stateText = "Protokoll erstellt";
    stateColor = "yellow";
  } else if (appointmentCount > 0) {
    stateText = `Termine fix: ${confirmedAppointmentCount}/${appointmentCount}`;
    stateColor = "yellow";
  } else {
    stateText = "Objekt angelegt";
    stateColor = "yellow";
  }

  return { stateText, stateColor };
};

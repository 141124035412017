import { groupBy, sortBy } from "lodash";
import { AppointmentsGroupedByState } from "../hooks/useObjectAppointments";

const stateOrderMapping = {
  open: 3,
  suggestion: 2,
  confirmed: 1,
};

export const groupAppointments = (appointments: any[]) => {
  const groupedDict = groupBy(appointments, (a: any) => a.get("state"));

  const groupedArray = Object.keys(groupedDict).map((state) => {
    return {
      state,
      appointments: groupedDict[state],
    } as AppointmentsGroupedByState;
  });

  const sortedByState = sortBy(
    groupedArray,
    (a) => stateOrderMapping[a.state] ?? 4
  );

  return sortedByState;
};

import { thumbnailOrOriginalUrl } from "../../../helper/thumbnail-helper";
import { CrackListItem } from "./area-to-image-table.helper";

type Props = {
  crackListItem: CrackListItem;
};

export default function WebProtocolStreetCrackListItem({
  crackListItem,
}: Props) {
  const { crack } = crackListItem;

  const detailPhotos = crack?.detailPhotos ?? [];

  return (
    <>
      {detailPhotos.map((photo) => (
        <div key={photo.url} className="crack-list-item">
          <div className="photo">
            <a href={photo.url} target="_blank">
              <img src={thumbnailOrOriginalUrl(photo)} />
            </a>
          </div>
        </div>
      ))}
    </>
  );
}

import { Stack } from "native-base";
import CreateProjectButton from "../header/CreateProjectButton.web";
import Headline from "../typo/Headline";
import Spacer from "./components/Spacer";
import ProjectsList from "./components/table/ProjectsList";

export default function Projects() {
  return (
    <Stack px={5} py={6}>
      <Headline title="Auftragsübersicht" />

      <CreateProjectButton />

      <Spacer />

      <ProjectsList />
    </Stack>
  );
}

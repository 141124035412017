import { format, isValid, setDefaultOptions } from "date-fns";
import de from "date-fns/locale/de";

setDefaultOptions({ locale: de });

export const formatHours = (input: Date | undefined | null) => {
  if (!input || !isValid(input)) {
    return;
  }

  return format(input, "p", { locale: de });
};

export const formatDay = (input: Date | undefined | null) => {
  if (!input || !isValid(input)) {
    return;
  }

  return format(input, "PPP", { locale: de });
};

export const formatDate = (input: Date | undefined | null) => {
  if (!input || !isValid(input)) {
    return;
  }

  return format(input, "P", { locale: de });
};

export const formatDateTime = (input: Date | undefined | null) => {
  if (!input || !isValid(input)) {
    return;
  }

  return format(input, "PPP '-' p 'Uhr'", { locale: de });
};

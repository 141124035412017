import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Stack } from "native-base";
import { useProjectWebAccessAccounts } from "../../hooks/useProjectWebAccessAccounts.web";
import TextFormInput from "../form/TextFormInput.web";
import CreateWebAccess from "../web/CreateWebAccess";
import ObjectWebAccessItems from "../object/ObjectWebAccessItems";
import WebAccessCode from "../object/WebAccessCode";

type Props = {
  project: DocumentSnapshot<DocumentData>;
  updateProject: (data: any) => Promise<void>;
};

export default function ProjectWebAccess({ project, updateProject }: Props) {
  const [accounts] = useProjectWebAccessAccounts(project.id);

  return (
    <Stack space={4}>
      <TextFormInput
        label="Projekt ID"
        fieldPath="webAccessId"
        doc={project}
        updateDoc={updateProject}
      />

      <WebAccessCode webAccessId={project.get("webAccessId")} />

      <ObjectWebAccessItems
        webAccessAccounts={accounts}
        webAccessId={project?.get("webAccessId") ?? ""}
      />

      <CreateWebAccess projectId={project.id} />
    </Stack>
  );
}

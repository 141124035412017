import { useCallback } from "react";
import { DragStartEvent, DragEndEvent } from "@dnd-kit/core";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { UpdateAreaSorting } from "./useAreas.web";

export const useSortAreas = (
  areas: QueryDocumentSnapshot<DocumentData>[],
  allAreas: QueryDocumentSnapshot<DocumentData>[],
  updateAreaSorting: (updatedOrders: UpdateAreaSorting) => Promise<void>,
  setDraggingArea?: (id?: string) => void
) => {
  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      const updatedOrders: UpdateAreaSorting = [];

      const activeArea = allAreas.find((a) => a.id === active.id);

      if (activeArea && over != null && active.id !== over.id) {
        const siblings = allAreas.filter(
          (a) => a.get("parentId") === activeArea.get("parentId")
        );

        const fromIndex = siblings.findIndex((a) => a.id === active.id);
        const toIndex = siblings.findIndex((a) => a.id === over.id);

        if (fromIndex >= 0 && toIndex >= 0) {
          if (fromIndex < toIndex) {
            // moving from left to right
            for (let i = fromIndex + 1; i <= toIndex; i++) {
              const currentItem = siblings[i];

              updatedOrders.push({
                doc: currentItem,
                order: parseInt(currentItem.get("order")) - 1,
              });
            }
          } else {
            // moving from right to left
            for (let i = toIndex; i <= fromIndex - 1; i++) {
              const currentItem = siblings[i];

              console.log("currentItem", i, siblings, currentItem);

              updatedOrders.push({
                doc: currentItem,
                order: parseInt(currentItem.get("order")) + 1,
              });
            }
          }

          updatedOrders.push({
            doc: siblings[fromIndex],
            order: siblings[toIndex].get("order"),
          });

          await updateAreaSorting(updatedOrders);
        }

        setDraggingArea && setDraggingArea(undefined);
      }
    },
    [areas, setDraggingArea]
  );

  const areasToSort: { id: string }[] = areas.map(({ id }) => ({ id }));

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setDraggingArea && setDraggingArea(event.active.id as string);
    },
    [setDraggingArea]
  );

  return { areasToSort, handleDragEnd, handleDragStart };
};

import React, { useState } from "react";
import { Box, Button, HStack, Icon, Modal } from "native-base";
import { Feather } from "@expo/vector-icons";
import PhotoThumbnail from "../../shared/PhotoThumbnail";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import DeleteButton from "../../shared/DeleteButton.web";
import PhotoThumbnailWithCrackMarks from "../../shared/PhotoThumbnailWithCrackMarks.web";
import { getFilenameFromUrl } from "../../../helper/filename-helper";

type Props = {
  objectName: string;
  photo: DocumentSnapshot<DocumentData> | undefined;
  crack?: DocumentSnapshot<DocumentData>;
  onClose: () => void;
  crackMarks?: DocumentSnapshot<DocumentData>[];
  isDisabled?: boolean;
};

export default function PhotoModal({
  objectName,
  photo,
  crack,
  onClose,
  crackMarks,
  isDisabled,
}: Props) {
  const [isEditModeActive, setEditModeActive] = useState(false);

  const imageUrl = photo?.get("url");
  const filename = getFilenameFromUrl(imageUrl);

  let modalContent: React.ReactNode | null = null;
  if (photo) {
    modalContent =
      crackMarks && crack ? (
        <PhotoThumbnailWithCrackMarks
          key={photo.id}
          photo={photo as any}
          crack={crack as any}
          isEditModeActive={isEditModeActive}
          showOriginal
        />
      ) : (
        <PhotoThumbnail key={photo.id} photo={photo as any} showOriginal />
      );
  }

  const actionButtons =
    crackMarks && isEditModeActive ? (
      <>
        <Box />
        <Button
          minW="120"
          onPress={() => setEditModeActive(false)}
          alignSelf="flex-end"
        >
          Fertig
        </Button>
      </>
    ) : (
      <>
        {!isDisabled && (
          <>
            <DeleteButton
              title={`${objectName} löschen`}
              description={`Sind Sie sich sicher, dass Sie dieses ${objectName} löschen wollen?`}
              object={photo as any}
              afterDelete={onClose}
              withIcon
              noMargin
            />

            {crackMarks && (
              <Button
                startIcon={<Icon as={Feather} name="edit-2" />}
                variant="outline"
                onPress={() => setEditModeActive(true)}
              >
                Markierungen bearbeiten
              </Button>
            )}
          </>
        )}

        <a href={imageUrl} target="_blank" download={filename}>
          <Button startIcon={<Icon as={Feather} name="download-cloud" />}>
            Herunterladen
          </Button>
        </a>
      </>
    );

  return (
    <Modal isOpen={Boolean(photo)} onClose={onClose}>
      <Modal.Content maxH="80vh" minW="80vw">
        <Modal.CloseButton />
        <Modal.Header>{objectName}</Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <HStack space={2} justifyContent="space-between" flex={1}>
            {actionButtons}
          </HStack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

import React, { useCallback, useState } from "react";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { Box, Icon, Pressable, Spinner, Stack, Text } from "native-base";
import { Feather } from "@expo/vector-icons";
import Subsection from "../typo/Subsection";
import PhotoButton from "../shared/PhotoButton";
import {
  dragActiveStyle,
  useUploadPhotoToObject,
} from "../../hooks/useUploadPhotoToObject";
import { useOverviewMaps } from "../../hooks/useOverviewMaps.web";
import PhotoModal from "./capture/PhotoModal";
import PhotoThumbnail from "../shared/PhotoThumbnail";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function OverviewMap({ object }: Props) {
  const [photoInModal, setPhotoInModal] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >(undefined);

  const onClose = useCallback(() => {
    setPhotoInModal(undefined);
  }, [setPhotoInModal]);

  const [uploadPhoto, isUploading, dropzone] = useUploadPhotoToObject(
    "overviewMap",
    object.ref.parent.parent!.id,
    object.id,
    undefined,
    1
  );

  const { getRootProps, isDragAccept } = dropzone;

  const [overviewMaps, isLoading] = useOverviewMaps(
    object.ref.parent.parent!.id,
    object.id
  );

  const overviewMap = overviewMaps && overviewMaps[0];

  return (
    <Stack space={4} mb={4}>
      <Subsection title="Übersichtskarte" />

      {!isLoading && (
        <Box w="400" h="300">
          {overviewMap ? (
            <Pressable
              onPress={() => {
                setPhotoInModal(overviewMap as any);
              }}
            >
              <PhotoThumbnail key={overviewMap.id} photo={overviewMap as any} />
            </Pressable>
          ) : (
            <div {...getRootProps()} style={dragActiveStyle(isDragAccept)}>
              <PhotoButton onPress={uploadPhoto}>
                <Stack space={2} alignItems="center">
                  {isUploading ? (
                    <Spinner color="white" />
                  ) : (
                    <Icon as={Feather} name="plus" color="white" size="xl" />
                  )}
                  <Text color="white" fontSize="sm" textAlign="center">
                    Übersichtskarte hochladen
                  </Text>
                </Stack>
              </PhotoButton>
            </div>
          )}
        </Box>
      )}

      <PhotoModal
        objectName="Übersichtskarte"
        photo={photoInModal}
        onClose={onClose}
      />
    </Stack>
  );
}

import { ReactNode, useEffect } from "react";
import { LayoutChangeEvent } from "react-native";
import useMeasure from "react-use/lib/useMeasure";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { AspectRatio, Icon, View } from "native-base";
import { Feather } from "@expo/vector-icons";
import { usePhotoThumbnail } from "../../hooks/usePhotoThumbnail";
import Image from "./Image";

type Props = {
  photo: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  showOriginal?: boolean;
  children?: ReactNode;
  onLayout?: ((event: LayoutChangeEvent) => void) | undefined;
};

export default function PhotoThumbnail({
  photo,
  showOriginal,
  onLayout,
  children,
}: Props) {
  const [imageURI, isUploading] = usePhotoThumbnail(photo, showOriginal);
  const [ref, { width, height }] = useMeasure();

  useEffect(() => {
    if (onLayout && width != null && height != null) {
      onLayout({ nativeEvent: { layout: { width, height } } } as any);
    }
  }, [width, height]);

  return (
    <AspectRatio
      ratio={4 / 3}
      bg="gray.50"
      borderRadius="2"
      ref={ref}
      overflow="hidden"
    >
      <View flex={1} position="relative">
        {imageURI && <Image imageURI={imageURI} />}

        {photo && isUploading && (
          <View
            w="full"
            h="full"
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <Icon
              as={Feather}
              name="refresh-cw"
              color="white"
              size="md"
              opacity="70"
            />
          </View>
        )}

        {children}
      </View>
    </AspectRatio>
  );
}

import React, { useCallback, useState } from "react";
import { Button, Center, Image, Input, Stack, useToast } from "native-base";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import VermflexLogo from "../../assets/vermflex-logo.png";
import { auth } from "../../App.web";

export default function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [eMail, setEMail] = useState("");
  const [password, setPassword] = useState("");

  const login = useCallback(async () => {
    try {
      setIsLoggingIn(true);

      await signInWithEmailAndPassword(auth, eMail, password);
      navigate("/admin/projects");
    } catch (e: any) {
      let title = "Login fehlgeschlagen";

      switch (e.code) {
        case "auth/invalid-email":
          title = "Ungültige E-Mail-Adresse eingegeben";
          break;
        case "auth/user-disabled":
          title = "Der Zugang wurde gesperrt";
          break;
        case "auth/wrong-password":
        case "auth/user-not-found":
          title = "Bitte überprüfen Sie die E-Mail-Adresse und das Passwort";
          break;
      }

      toast.show({ placement: "top", title });

      setPassword("");
    } finally {
      setIsLoggingIn(false);
    }
  }, [eMail, password, setIsLoggingIn, toast, navigate]);

  return (
    <Center minH="100vh">
      <Stack space={4} w="75%" maxW="300px" mx="auto">
        <Image
          source={{ uri: VermflexLogo }}
          w="full"
          h={12}
          resizeMode="contain"
          alt="vermflex"
          mb={8}
        />

        <Input
          value={eMail}
          onChangeText={(text) => setEMail(text)}
          placeholder="E-Mail"
          keyboardType="email-address"
          bg="white"
          autoFocus
          returnKeyType="next"
        />

        <Input
          value={password}
          onChangeText={(text) => setPassword(text)}
          placeholder="Passwort"
          type="password"
          bg="white"
          secureTextEntry
          onSubmitEditing={login}
          returnKeyType="go"
        />

        <Button
          mt={4}
          onPress={login}
          isLoading={isLoggingIn}
          isDisabled={isLoggingIn}
        >
          Einloggen
        </Button>
      </Stack>
    </Center>
  );
}

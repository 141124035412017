import { useFirebaseObject } from "./useFirebaseObject";

export const DEFAULT_CRACK_MARK_SIZE = 25;

export const useCrack = (
  projectId: string,
  objectId: string,
  crackId: string
) => {
  const [crack, updateCrack, isLoading] = useFirebaseObject(
    ["projects", projectId, "objects", objectId, "cracks", crackId].join("/")
  );

  return { crack, updateCrack, isLoading };
};

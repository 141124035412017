import React, { useCallback } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Center, Image, Stack, useToast } from "native-base";
import { object, string } from "yup";
import Constants from "expo-constants";
import VermflexLogo from "../../../assets/vermflex-logo.png";
import { formikErrorMessages } from "../../../helper/formik-helper";
import FormikTextFormInput from "../../form/FormikTextFormInput";
import { Protocol } from "../WebAccess";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

type FormValues = {
  projectOrObjectId: string;
  code: string;
};

const validationSchema = object().shape({
  projectOrObjectId: string().required(formikErrorMessages.required),
  code: string().required(formikErrorMessages.required),
});

type Props = {
  projectOrObjectId: string | undefined;
  setProtocols: (protocols: Protocol[]) => void;
};

export default function WebAccessLogin({
  projectOrObjectId,
  setProtocols,
}: Props) {
  const toast = useToast();

  const initialValues = {
    projectOrObjectId: projectOrObjectId ?? "",
    code: "",
  };

  const checkAccess = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { projectOrObjectId, code } = values;
      const { setSubmitting, resetForm } = formikHelpers;

      try {
        const response = await fetch(`${BACKEND_API_BASE_URL}/protocol`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectOrObjectId,
            code,
          }),
        });

        const json = await response.json();

        const { success, protocols } = json;

        if (!success) {
          throw new Error(JSON.stringify(json));
        }

        if (!protocols || protocols.length <= 0) {
          toast.show({
            placement: "top",
            title:
              "Kein Webprotokoll gefunden. Bitte prüfen Sie die eingegebene Projekt ID und den Zugangscode und versuchen Sie es erneut.",
          });
        } else {
          setProtocols(protocols);
        }

        resetForm({ values: { projectOrObjectId, code: "" } });
      } catch (e) {
        console.error(e);
        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setSubmitting(false);
    },
    [toast]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={checkAccess}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Center minH="100vh">
            <Stack space={2} w="75%" maxW="300px" mx="auto">
              <Image
                source={{ uri: VermflexLogo }}
                w="full"
                h={12}
                resizeMode="contain"
                alt="vermflex"
                mb={8}
              />

              <FormikTextFormInput
                fieldPath="projectOrObjectId"
                label="Projekt ID"
              />

              <FormikTextFormInput fieldPath="code" label="Zugangscode" />

              <Button
                mt={4}
                onPress={submitForm}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Zum Webprotokoll
              </Button>
            </Stack>
          </Center>
        </Form>
      )}
    </Formik>
  );
}

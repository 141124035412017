import React from "react";
import { Tooltip } from "native-base";

type Props = {
  tooltip: string;
  children: React.ReactNode;
};

export default function TableIconHeadline({ tooltip, children }: Props) {
  return (
    <th style={{ width: "70px" }}>
      <Tooltip label={tooltip} placement="top">
        <span>{children}</span>
      </Tooltip>
    </th>
  );
}

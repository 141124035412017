import { useMemo } from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { orderBy } from "lodash";
import { useFirestoreCollection } from "./useFirestoreCollection";

export const useObjectPhotos = (projectId: string, objectId: string) => {
  const [unsortedCracks, isLoadingCracks] = useFirestoreCollection(
    ["projects", projectId, "objects", objectId, "cracks"].join("/")
  );

  const [unsortedPhotos, isLoadingPhotos] = useFirestoreCollection(
    ["projects", projectId, "objects", objectId, "photos"].join("/")
  );

  const cracks = useMemo(
    () => orderBy(unsortedCracks, [(a) => a.get("createdAt")], ["asc"]),
    [unsortedCracks]
  );

  const photos = useMemo(
    () => orderBy(unsortedPhotos, [(a) => a.get("capturedAt")], ["asc"]),
    [unsortedPhotos]
  );

  const [photosByCrackId, photosByAreaId] = useMemo(() => {
    const photosByCrackId = new Map<
      string,
      FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
    >();

    const photosByAreaId = new Map<
      string,
      FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
    >();

    for (const photo of photos) {
      const crackRef =
        photo.get<FirebaseFirestoreTypes.DocumentReference>("crackRef");

      const crack = cracks.find(
        (c) => crackRef && c.ref.path === crackRef.path
      );

      if (crack) {
        const currentPhotosByCrackId = photosByCrackId.get(crack.id) || [];
        photosByCrackId.set(crackRef.id, [...currentPhotosByCrackId, photo]);

        const areaRef =
          crack.get<FirebaseFirestoreTypes.DocumentReference>("areaRef");

        if (areaRef.id) {
          const currentPhotosByAreaId = photosByAreaId.get(areaRef.id) || [];
          photosByAreaId.set(areaRef.id, [...currentPhotosByAreaId, photo]);
        }
      }
    }

    return [photosByCrackId, photosByAreaId] as const;
  }, [photos, cracks]);

  const isLoading = isLoadingCracks || isLoadingPhotos;

  return { photos, photosByCrackId, photosByAreaId, isLoading };
};

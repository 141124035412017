import React from "react";
import { HStack, ScrollView } from "native-base";
import { Outlet } from "react-router-dom";
import Navigation from "../components/web/Navigation";
import { GestureHandlerRootView } from "react-native-gesture-handler";

export default function Root() {
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <HStack flex={1}>
        <Navigation />

        <ScrollView height="100vh">
          <Outlet />
        </ScrollView>
      </HStack>
    </GestureHandlerRootView>
  );
}

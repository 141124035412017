import React, { useCallback } from "react";
import {
  Button,
  FormControl,
  Modal,
  Pressable,
  ScrollView,
  Stack,
  Text,
} from "native-base";
import { object, string } from "yup";
import { useAreaLogic } from "../../../hooks/useAreaLogic";
import { ObjectType } from "../../../hooks/useObjectType";
import FormikTextFormInput from "../../form/FormikTextFormInput";
import { Formik, FormikHelpers } from "formik";
import { formikErrorMessages } from "../../../helper/formik-helper";
import { useSuggestions } from "../../../hooks/useSuggestions";

type Props = {
  title: string;
  onAddAreaWithName: (areaName: string | undefined) => void;
  level?: number;
  objectType: ObjectType | null;
  rootAreaType?: string;
  onClose: () => void;
};

const validationSchema = object().shape({
  title: string().required(formikErrorMessages.required),
});

type FormValues = {
  title: string;
};

export default function AddAreaModal({
  title,
  onAddAreaWithName,
  level,
  objectType,
  rootAreaType,
  onClose,
}: Props) {
  const onOK = useCallback(
    ({ title }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      onAddAreaWithName(title);
      onClose();
      setSubmitting(false);
    },
    [onAddAreaWithName, onClose]
  );

  const { suggestionsForLevel } = useAreaLogic(
    objectType,
    level!,
    rootAreaType
  );

  const [suggestions] = useSuggestions(
    "area",
    "",
    suggestionsForLevel,
    rootAreaType
  );

  const initialValues: FormValues = { title: "" };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onOK}
    >
      {({ isSubmitting, isValid, submitForm, setFieldValue }) => (
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />

          <Modal.Header>{title}</Modal.Header>

          <Modal.Body>
            <FormikTextFormInput fieldPath="title" label="Bereich" />

            <FormControl.Label mt={4}>Vorschläge</FormControl.Label>

            <ScrollView maxHeight={400}>
              <Stack>
                {suggestions.map((s) => {
                  const currentTitle = s.get<string>("title");

                  return (
                    <Pressable
                      key={s.id}
                      py={1}
                      onPress={() => setFieldValue("title", currentTitle)}
                    >
                      {({ isHovered }) => (
                        <Text color={isHovered ? "primary.400" : "black"}>
                          {currentTitle}
                        </Text>
                      )}
                    </Pressable>
                  );
                })}
              </Stack>
            </ScrollView>
          </Modal.Body>

          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
              >
                Abbrechen
              </Button>

              <Button
                onPress={submitForm}
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Erstellen
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      )}
    </Formik>
  );
}

import { useMemo } from "react";
import { orderBy } from "lodash";
import { useFirestoreCollection } from "./useFirestoreCollection";

export const useObjects = (projectId: string) => {
  const [unsortedObjects, isLoading] = useFirestoreCollection(
    ["projects", projectId, "objects"].join("/")
  );

  const objects = useMemo(
    () => orderBy(unsortedObjects, [(a) => a.get("createdAt")], ["asc"]),
    [unsortedObjects]
  );

  return [objects, isLoading] as const;
};

import { ObjectType } from "./useObjectType";

export const useAreaLogic = (
  objectType: ObjectType | null,
  level: number,
  rootAreaType: string | undefined
) => {
  let canAddCracks = false;
  let canAddSubareas = false;
  let suggestionsForLevel = 1;

  switch (objectType) {
    case "familyHome":
      if (rootAreaType === "outdoor") {
        canAddCracks = level === 2;
        canAddSubareas = level <= 1;

        if (level === 2) {
          suggestionsForLevel = 4;
        }
      } else {
        canAddCracks = level === 3;
        canAddSubareas = level <= 2;

        if (level === 3) {
          suggestionsForLevel = 4;
        } else {
          suggestionsForLevel = level;
        }
      }

      break;

    case "apartmentHouse":
      if (rootAreaType === "outdoor") {
        canAddCracks = level === 2;
        canAddSubareas = level <= 1;

        if (level === 2) {
          suggestionsForLevel = 4;
        }
      } else {
        canAddCracks = level === 4;
        canAddSubareas = level <= 3;
        suggestionsForLevel = level;
      }

      break;

    case "street":
      canAddCracks = level === 1;
      canAddSubareas = false;
      suggestionsForLevel = 1;

      break;
  }

  return { canAddSubareas, canAddCracks, suggestionsForLevel };
};

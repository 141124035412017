import React from "react";
import { useFormikContext } from "formik";
import { get } from "lodash";
import { FormControl, Input, WarningOutlineIcon } from "native-base";

type Props = {
  fieldPath: string;
  label?: string;
  placeholder?: string;
  type?: "text" | "password";
};

export default function FormikTextFormInput({
  fieldPath,
  label,
  placeholder,
  type,
}: Props) {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext();

  const errorMessage = get(errors, fieldPath);
  const isFieldTouched = get(touched, fieldPath);
  const isInvalid = errorMessage != null && isFieldTouched;

  return (
    <FormControl mb="1" isInvalid={isInvalid}>
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Input
        bg="white"
        placeholder={placeholder}
        onChangeText={handleChange(fieldPath)}
        onBlur={handleBlur(fieldPath)}
        value={get(values, fieldPath)}
        type={type}
      />

      <FormControl.ErrorMessage
        leftIcon={<WarningOutlineIcon size="xs" />}
        mb={3}
      >
        {errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );
}

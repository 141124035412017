import { useCallback } from "react";
import { addDoc, collection, doc, Timestamp } from "firebase/firestore";
import { firestore } from "../App.web";
import { useObjectType } from "./useObjectType";

type CrackType = "original" | "followUp";

export const useCreateCrack = (
  projectId: string,
  objectId: string,
  areaId: string
) => {
  const objectType = useObjectType(projectId!, objectId!);

  return useCallback(
    async (
      mainCrackId?: string,
      crackType?: CrackType,
      noVisibleChange?: boolean
    ) => {
      const areaRef = doc(
        firestore,
        ["projects", projectId, "objects", objectId, "areas", areaId].join("/")
      );
      const data: any = {
        areaRef,
        lengthUnit: "cm",
        createdAt: Timestamp.now(),
      };

      if (crackType) {
        data.crackType = crackType;
      }

      if (mainCrackId) {
        data.mainCrackId = mainCrackId;
      }

      if (noVisibleChange) {
        data.noVisibleChange = noVisibleChange;
      }

      if (objectType === "street") {
        data.isHiddenInProtocol = true;
      }

      return addDoc(
        collection(
          firestore,
          ["projects", projectId, "objects", objectId, "cracks"].join("/")
        ),
        data
      );
    },
    [objectType]
  );
};

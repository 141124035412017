import SegmentedControl, {
  NativeSegmentedControlIOSChangeEvent,
} from "@react-native-segmented-control/segmented-control";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { NativeSyntheticEvent } from "react-native";
import { useCallback } from "react";

type Props = {
  fieldPath: string;
  options: string[];
  defaultOptionIndex: number;
  doc: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateDoc: (data: any) => Promise<void>;
  isDisabled?: boolean;
};

export default function SegmentedControlFormInput({
  fieldPath,
  options,
  defaultOptionIndex,
  doc,
  updateDoc,
  isDisabled,
}: Props) {
  const value = doc.get(fieldPath);

  const onChange = useCallback(
    (e: NativeSyntheticEvent<NativeSegmentedControlIOSChangeEvent>) => {
      updateDoc({ [fieldPath]: e.nativeEvent.value ?? null });
    },
    [updateDoc, fieldPath]
  );

  const selectedIndex = options.findIndex((x) => x === value);

  return (
    <SegmentedControl
      values={options}
      selectedIndex={selectedIndex >= 0 ? selectedIndex : defaultOptionIndex}
      onChange={onChange}
      enabled={!isDisabled}
    />
  );
}

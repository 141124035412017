export const useAuthorFields = (user: any) => {
  if (!user) {
    return {
      name: "",
      eMail: "",
      phone: "",
      address: "",
      signatureImageUrl: "",
    };
  }

  const { protocolAuthor, phone, eMail, name, signatureImageUrl } =
    user.data() || {};

  return {
    name: name ?? "",
    eMail: eMail ?? "",
    phone: phone ?? "",
    address: protocolAuthor ?? "",
    signatureImageUrl: signatureImageUrl ?? "",
  };
};

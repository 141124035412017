import React, { useCallback, useState } from "react";
import { Button, Icon, Modal } from "native-base";
import { Feather } from "@expo/vector-icons";
import { ObjectType } from "../../../hooks/useObjectType";
import AddAreaModal from "../../web/components/AddAreaModal";

type Props = {
  onAddAreaWithName: (areaName: string | undefined) => void;
  level?: number;
  objectType: ObjectType | null;
  rootAreaType?: string;
};

export default function AddAreaButton({
  onAddAreaWithName,
  level,
  objectType,
  rootAreaType,
}: Props) {
  const [isModalShown, setModalShown] = useState(false);

  const openModal = useCallback(() => {
    setModalShown(true);
  }, [setModalShown]);

  const onClose = useCallback(() => {
    setModalShown(false);
  }, [setModalShown]);

  const title = `${level === 1 ? "Bereich" : "Unterbereich"} hinzufügen`;

  return (
    <>
      <Button
        onPress={openModal}
        size="xs"
        variant="outline"
        leftIcon={<Icon as={Feather} name="plus" />}
        alignSelf="flex-start"
      >
        {title}
      </Button>

      <Modal isOpen={isModalShown} onClose={onClose}>
        {isModalShown && (
          <AddAreaModal
            title={title}
            onAddAreaWithName={onAddAreaWithName}
            level={level}
            objectType={objectType}
            rootAreaType={rootAreaType}
            onClose={onClose}
          />
        )}
      </Modal>
    </>
  );
}

import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../shared/DeleteButton";

type Props = {
  project: DocumentSnapshot<DocumentData>;
};

export default function DeleteProjectButton({ project }: Props) {
  const navigate = useNavigate();

  const afterDelete = useCallback(() => {
    navigate("/admin/projects");
  }, [navigate]);

  return (
    <DeleteButton
      title="Auftrag löschen"
      description="Sind Sie sich sicher, dass Sie diesen Auftrag löschen wollen?"
      object={project as any}
      afterDelete={afterDelete}
    />
  );
}

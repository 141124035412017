import { useCallback } from "react";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { firestore } from "../App.web";

export const useCreateObject = (projectId: string) => {
  return useCallback(
    async () =>
      addDoc(
        collection(firestore, ["projects", projectId, "objects"].join("/")),
        {
          createdAt: Timestamp.now(),
        }
      ),
    []
  );
};

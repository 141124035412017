import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { isEmpty } from "lodash";
import { Divider, Stack } from "native-base";
import CrackListItem from "./CrackListItem";

type Props = {
  cracks:
    | FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
    | undefined;
  allCracks:
    | FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
    | undefined;
  photosByCrackId: Map<
    string,
    FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
  >;
  projectId: string;
  objectId: string;
  areaId: string;
  rootAreaType?: string;
  isFollowUpInspection: boolean;
};

export default function CrackList({
  cracks,
  allCracks,
  photosByCrackId,
  projectId,
  objectId,
  areaId,
  rootAreaType,
  isFollowUpInspection,
}: Props) {
  if (!cracks || isEmpty(cracks)) {
    return null;
  }

  return (
    <Stack space={8} divider={<Divider />}>
      {cracks.map((c) => (
        <CrackListItem
          key={c.id}
          crack={c}
          allCracks={allCracks || []}
          photosByCrackId={photosByCrackId}
          projectId={projectId}
          objectId={objectId}
          areaId={areaId}
          rootAreaType={rootAreaType}
          isFollowUpInspection={isFollowUpInspection}
        />
      ))}
    </Stack>
  );
}

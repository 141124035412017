import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { AddIcon, Button } from "native-base";
import { useCreateObject } from "../../hooks/useCreateObject";

type Props = {
  project: DocumentSnapshot<DocumentData>;
};

export default function CreateObjectButton({ project }: Props) {
  const navigate = useNavigate();

  const createObject = useCreateObject(project.id);

  const onCreateObject = useCallback(async () => {
    const object = await createObject();

    navigate(`/admin/projects/${project.id}/${object.id}`);
  }, [navigate, createObject]);

  return (
    <Button
      onPress={onCreateObject}
      colorScheme="primary"
      startIcon={<AddIcon />}
      alignSelf="flex-start"
    >
      Objekt hinzufügen
    </Button>
  );
}

import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { HStack, Text } from "native-base";
import StateIndicator from "../shared/StateIndicator";
import { useProjectState } from "../../hooks/useProjectState";

type Props = {
  project: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
};

export default function ProjectState({ project }: Props) {
  const { stateText, stateColor } = useProjectState(project);

  if (!stateText || !stateColor) {
    return null;
  }

  return (
    <HStack space={2}>
      <StateIndicator stateColor={stateColor} />
      <Text>{stateText}</Text>
    </HStack>
  );
}

import React, { useContext } from "react";
import { Stack, Text } from "native-base";
import Headline from "../typo/Headline";
import LogoutButton from "./components/LogoutButton";
import Spacer from "./components/Spacer";
import { CurrentUserContext } from "../../App.web";

export default function Profile() {
  const currentUser = useContext(CurrentUserContext);

  return (
    <Stack px={5} py={6}>
      <Headline title="Account" />

      {currentUser && (
        <>
          <Text>Eingeloggt als {currentUser.email}</Text>

          <Spacer />
        </>
      )}

      <LogoutButton />
    </Stack>
  );
}

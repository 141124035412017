import React from "react";
import { useParams } from "react-router-dom";
import createGlobalState from "react-use/lib/factory/createGlobalState";
import { ObjectType } from "../../hooks/useObjectType";
import WebAccessLogin from "./protocol/WebAccessLogin";
import WebProtocol from "./protocol/WebProtocol";
import WebProtocolSelector from "./protocol/WebProtocolSelector";

export type Photo = {
  url: string;
  thumbnailUrl?: string;
  width: number;
  height: number;
};

type CrackMark = {
  x: number;
  y: number;
  size: number;
};

export type CrackType = "original" | "followUp";

export type Crack = {
  id: string;
  title?: string;
  description?: string;
  length?: string;
  lengthUnit?: string;
  position?: string;
  width?: string;
  crackMarks: CrackMark[];
  photo?: Photo;
  detailPhotos?: Photo[];
  crackType?: CrackType;
  mainCrackId?: string;
  noVisibleChange?: boolean;
};

export type Area = {
  id: string;
  level: number;
  title?: string;
  notes?: string;
  cracks: Crack[];
  children: Area[];
};

export type Protocol = {
  projectId: string;
  objectId: string;
  title?: string;
  constructionSite?: string;
  objectType: ObjectType;
  projectNumber?: string;
  client?: string;
  address?: string;
  propertyNumber?: string;
  protocolAuthor?: string;
  owner?: string;
  captureDate?: Date;
  totalPhotoCount: number;
  areas: Area[];
  overviewPhotos: Photo[];
  overviewMaps: Photo[];
  protocolPDFUrl: string;
  downloadRequestedAt?: Date;
  downloadUploadedAt?: Date;
  downloadUrl?: string;
  isFollowUpInspection?: boolean;
};

const useGlobalProtocols = createGlobalState<Protocol[] | undefined>();

export default function WebAccess() {
  const { projectOrObjectId, protocolIndex } = useParams();

  const [protocols, setProtocols] = useGlobalProtocols();

  if (protocols?.length === 1) {
    return <WebProtocol protocol={protocols[0]} />;
  }

  const protocolIndexAsNumber =
    protocolIndex && protocolIndex !== "" ? Number(protocolIndex) : undefined;

  if (
    protocols &&
    protocols.length > 0 &&
    protocolIndexAsNumber != null &&
    protocols[protocolIndexAsNumber]
  ) {
    return <WebProtocol protocol={protocols[protocolIndexAsNumber]} />;
  }

  if (protocols) {
    return <WebProtocolSelector protocols={protocols} />;
  }

  return (
    <WebAccessLogin
      projectOrObjectId={projectOrObjectId}
      setProtocols={setProtocols}
    />
  );
}

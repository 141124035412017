import { AddIcon, Button } from "native-base";
import { useCreateAppointment } from "../../../hooks/useCreateAppointment";

type Props = {
  projectId: string;
  objectId: string;
};

export default function CreateAppointmentButton({
  projectId,
  objectId,
}: Props) {
  const createAppointment = useCreateAppointment(projectId, objectId);

  return (
    <Button
      onPress={async () => {
        await createAppointment();
      }}
      colorScheme="primary"
      startIcon={<AddIcon />}
      alignSelf="flex-start"
    >
      Termin hinzufügen
    </Button>
  );
}

export const durationOptions = [
  {
    label: "Keine Angabe",
    value: "",
  },
  {
    label: "15 Minuten",
    value: "15",
  },
  {
    label: "30 Minuten",
    value: "30",
  },
  {
    label: "45 Minuten",
    value: "45",
  },
  {
    label: "1 Stunde",
    value: "60",
  },
  {
    label: "1:30 Stunden",
    value: "90",
  },
  {
    label: "2 Stunden",
    value: "120",
  },
  {
    label: "2:30 Stunden",
    value: "150",
  },
  {
    label: "3 Stunden",
    value: "180",
  },
  {
    label: "4 Stunden",
    value: "240",
  },
];

import { Box, Stack, Text } from "native-base";
import QRCode from "react-qr-code";

type Props = {
  webAccessId: string | null | undefined;
};

export default function WebAccessCode({ webAccessId }: Props) {
  const normalizedWebAccessId = (webAccessId ?? "").toUpperCase();
  const link = `https://rissprotokoll.vermflex.ch/${normalizedWebAccessId}`;

  if (!normalizedWebAccessId || normalizedWebAccessId === "") {
    return null;
  }

  return (
    <Stack space={4}>
      <Text>{`Link: ${link}`}</Text>

      <Box w="256" h="256">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={link}
          viewBox={`0 0 256 256`}
        />
      </Box>
    </Stack>
  );
}

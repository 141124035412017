import React, { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { Icon, IconButton, Pressable } from "native-base";
import { Feather } from "@expo/vector-icons";
import {
  deleteDoc,
  DocumentData,
  QueryDocumentSnapshot,
  updateDoc,
} from "firebase/firestore";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import { ImageSize } from "./MarkCracks";
import ConfirmDeletion from "../../shared/ConfirmDeletion";

type Props = {
  crackMarking: QueryDocumentSnapshot<DocumentData>;
  imageSize?: ImageSize;
};

export default function CrackMark({ crackMarking, imageSize }: Props) {
  const { x, y, size } = crackMarking.data();

  const [offset, setOffset] = useState({ x, y });
  const [startOffset, setStartOffset] = useState({ x, y });

  const [isConfirmationShown, setIsConfirmationShown] = useState(false);
  const onClose = useCallback(
    () => setIsConfirmationShown(false),
    [setIsConfirmationShown]
  );
  const onDelete = useCallback(async () => {
    setIsConfirmationShown(false);

    await deleteDoc(crackMarking.ref);
  }, [setIsConfirmationShown]);

  const updateCrackMarking = useCallback(
    async (data: any) => {
      await updateDoc(crackMarking.ref, data);
    },
    [crackMarking]
  );

  if (!imageSize) {
    return null;
  }

  const { width, height } = imageSize;

  const panGesture = Gesture.Pan()
    .runOnJS(true)
    .onUpdate((e) => {
      setOffset(() => {
        const newX = startOffset.x + (e.translationX / width) * 100;
        const newY = startOffset.y + (e.translationY / height) * 100;

        return {
          x: Math.max(0, Math.min(newX, 100)),
          y: Math.max(0, Math.min(newY, 100)),
        };
      });
    })
    .onEnd(() => {
      setStartOffset({
        x: offset.x,
        y: offset.y,
      });
    })
    .onFinalize(async () => {
      updateCrackMarking({ x: offset.x, y: offset.y });
    });

  const crackMarkSizeInPx = (size / 100) * width;

  // TODO: Delete button is sometimes not pressable correclty
  return (
    <>
      <GestureDetector gesture={panGesture}>
        <Pressable
          //@ts-ignore
          className="draggable"
          position="absolute"
          left={-crackMarkSizeInPx / 2}
          top={-crackMarkSizeInPx / 2}
          width={crackMarkSizeInPx}
          height={crackMarkSizeInPx}
          borderColor="#c2cf00"
          borderRadius="1000"
          borderWidth={5}
          style={[
            // This is a workaround because gesture handler sets cursor to auto when finished with dragging
            // @ts-ignore
            { $$css: true, cursor: "draggable" },
            {
              transform: [
                { translateX: (offset.x / 100) * width },
                { translateY: (offset.y / 100) * height },
              ],
            },
          ]}
          _hover={{
            borderColor: "red.500",
          }}
          onPress={() => {}}
        >
          <IconButton
            position="absolute"
            top={2}
            right={2}
            colorScheme="danger"
            icon={<Icon as={Feather} name="trash" color="danger.500" />}
            onPress={() => setIsConfirmationShown(true)}
          />
        </Pressable>
      </GestureDetector>

      <ConfirmDeletion
        isOpen={isConfirmationShown}
        title="Markierung entfernen"
        description="Sind Sie sich sicher, dass Sie diese Markierung entfernen wollen?"
        onDelete={onDelete}
        onClose={onClose}
      />
    </>
  );
}

export const crackMarkStyles = StyleSheet.create({
  crackMarkPosition: {
    position: "absolute",
    borderColor: "#c2cf00",
    borderRadius: 1000,
    borderWidth: 5,
  },
});

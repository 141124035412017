import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { HStack, Text } from "native-base";
import { useObjectState } from "../../hooks/useObjectState";
import StateIndicator from "../shared/StateIndicator";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
};

export default function ObjectState({ object }: Props) {
  const { stateText, stateColor } = useObjectState(object);

  return (
    <HStack space={2}>
      <StateIndicator stateColor={stateColor} />
      <Text>{stateText}</Text>
    </HStack>
  );
}

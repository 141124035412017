import React, { useCallback } from "react";
import { isEmpty } from "lodash";
import { Button } from "native-base";
import { Linking } from "react-native";

type Props = {
  format: "docx" | "pdf";
  url?: string;
};

const formatName = {
  docx: "Word",
  pdf: "PDF",
};

export default function DownloadProtocolButton({ format, url }: Props) {
  const isNotYetReady = isEmpty(url);

  const onDownload = useCallback(() => {
    if (url) {
      Linking.openURL(url);
    }
  }, [url]);

  return (
    <Button
      variant="solid"
      color="green.400"
      onPress={onDownload}
      isLoading={isNotYetReady}
      isDisabled={isNotYetReady}
    >
      {`Rissprotokoll (${formatName[format]})`}
    </Button>
  );
}

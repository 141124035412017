import { Image as ImageComponent } from "native-base";

type Props = {
  imageURI: string;
};

export default function Image({ imageURI }: Props) {
  return (
    <ImageComponent flex={1} source={{ uri: imageURI }} resizeMode="contain" />
  );
}

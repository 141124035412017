import { useCallback } from "react";
import { addDoc, collection, doc, Timestamp } from "firebase/firestore";
import { firestore } from "../App.web";

export const useCreateWebAccess = () => {
  return useCallback(
    async (
      projectId: string,
      objectId: string | undefined,
      eMail: string,
      code: string
    ) => {
      const data: any = {
        eMail,
        code,
        createdAt: Timestamp.now(),
      };

      if (objectId) {
        data.objectRef = doc(
          firestore,
          ["projects", projectId, "objects", objectId].join("/")
        );
      } else {
        data.projectRef = doc(firestore, ["projects", projectId].join("/"));
      }

      return addDoc(collection(firestore, "webAccessAccounts"), data);
    },
    []
  );
};

import React, { useCallback, useMemo, useState } from "react";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import {
  Pressable,
  Spinner,
  Stack,
  Icon,
  Text,
  Box,
  HStack,
} from "native-base";
import { Feather } from "@expo/vector-icons";
import { useOverviewPhotos } from "../../../hooks/useOverviewPhotos";
import Subsection from "../../typo/Subsection";
import PhotoThumbnail from "../../shared/PhotoThumbnail";
import PhotoModal from "./PhotoModal";
import PhotoButton from "../../shared/PhotoButton";
import {
  dragActiveStyle,
  useUploadPhotoToObject,
} from "../../../hooks/useUploadPhotoToObject";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function TitlePhotos({ object }: Props) {
  const [photoInModal, setPhotoInModal] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >(undefined);

  const onClose = useCallback(() => {
    setPhotoInModal(undefined);
  }, [setPhotoInModal]);

  const [overviewPhotos] = useOverviewPhotos(
    object.ref.parent.parent!.id,
    object.id
  );

  const numberOfAdditionalImagesAllowed = 2 - overviewPhotos.length;
  const canAddAdditionalOverviewPhotos = numberOfAdditionalImagesAllowed > 0;

  const [uploadPhoto, isUploading, dropzone] = useUploadPhotoToObject(
    "objectOverviewPhoto",
    object.ref.parent.parent!.id,
    object.id,
    undefined,
    numberOfAdditionalImagesAllowed
  );

  const { getRootProps, isDragAccept } = dropzone;

  const items = useMemo(() => {
    const i = [
      ...overviewPhotos.map((op) => (
        <Box key={op.id} w="400" h="300">
          <Pressable
            onPress={() => {
              setPhotoInModal(op as any);
            }}
          >
            <PhotoThumbnail key={op.id} photo={op} />
          </Pressable>
        </Box>
      )),
    ];

    if (canAddAdditionalOverviewPhotos) {
      i.push(
        <div
          key="add"
          {...getRootProps()}
          style={dragActiveStyle(isDragAccept)}
        >
          <Box w="400" h="300">
            <PhotoButton onPress={uploadPhoto}>
              <Stack space={2} alignItems="center">
                {isUploading ? (
                  <Spinner color="white" />
                ) : (
                  <Icon as={Feather} name="plus" color="white" size="xl" />
                )}
                <Text color="white" fontSize="sm" textAlign="center">
                  Titelfoto hinzufügen
                </Text>
              </Stack>
            </PhotoButton>
          </Box>
        </div>
      );
    }

    return i;
  }, [overviewPhotos, canAddAdditionalOverviewPhotos, isDragAccept]);

  return (
    <Stack space={4}>
      <Subsection title="Titelfotos" />

      <HStack space={4}>{items}</HStack>

      <PhotoModal
        objectName="Titelfoto"
        photo={photoInModal}
        onClose={onClose}
      />
    </Stack>
  );
}

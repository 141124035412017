import React, { useCallback } from "react";
import { Button, HStack, Text } from "native-base";
import { trim } from "lodash";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import TextAreaFormInput from "../../form/TextAreaFormInput";

type Props = {
  area: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateArea: (data: any) => Promise<void>;
};

type PrefilledText = {
  title: string;
  text: string;
};

const prefilledText: PrefilledText[] = [
  {
    title: "Kein Zugang",
    text: "Kein Zugang möglich",
  },
  {
    title: "Keine Risse",
    text: "Keine essenzielle Risse",
  },
  {
    title: "Keine Risse ersichtlich",
    text: "Keine essenzielle Risse ersichtlich",
  },
];

export default function AreaNotes({ area, updateArea }: Props) {
  const notes = area.get("notes");

  const prefillText = useCallback(
    (pt: PrefilledText) => {
      const newNotes = trim(`${notes ?? ""}\n${pt.text}`);

      updateArea({ notes: newNotes });
    },
    [notes, updateArea]
  );

  return (
    <>
      <TextAreaFormInput
        label="Bemerkungen"
        fieldPath="notes"
        doc={area}
        updateDoc={updateArea}
      />

      <HStack space={2} pt={1}>
        {prefilledText.map((pt) => (
          <Button
            variant="outline"
            key={pt.title}
            onPress={() => prefillText(pt)}
            flex={1}
          >
            <Text textAlign="center" color="primary.400">
              {pt.title}
            </Text>
          </Button>
        ))}
      </HStack>
    </>
  );
}

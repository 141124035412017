import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import DeleteButton from "../shared/DeleteButton.web";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function DeleteObjectButton({ object }: Props) {
  const navigate = useNavigate();

  const afterDelete = useCallback(() => {
    navigate("..");
  }, [navigate]);

  return (
    <DeleteButton
      title="Objekt löschen"
      description="Sind Sie sich sicher, dass Sie dieses Objekt löschen wollen?"
      object={object}
      afterDelete={afterDelete}
    />
  );
}

import { Select } from "native-base";
import { ProjectStates } from "../../../../hooks/useProjectState";

export type ProjectFilterType = "all" | "current" | ProjectStates;

type Props = {
  projectFilter: ProjectFilterType;
  setProjectFilter: (projectFilter: ProjectFilterType) => void;
};

const options: { label: string; value: ProjectFilterType }[] = [
  {
    label: "Aktiv",
    value: "current",
  },
  {
    label: "Zu erfassen",
    value: "toCapture",
  },
  {
    label: "In Bearbeitung",
    value: "inProgress",
  },
  {
    label: "Abgeschlossen",
    value: "done",
  },
  {
    label: "Alle",
    value: "all",
  },
];

export default function ProjectListFilter({
  projectFilter,
  setProjectFilter,
}: Props) {
  return (
    <Select
      selectedValue={projectFilter}
      placeholder="Status"
      mt={1}
      onValueChange={setProjectFilter as any}
      w="1/6"
    >
      {options.map(({ label, value }) => (
        <Select.Item key={value} label={label} value={value} />
      ))}
    </Select>
  );
}

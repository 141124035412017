import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import DeleteButton from "../../shared/DeleteButton";

type Props = {
  area: DocumentSnapshot<DocumentData>;
};

export default function DeleteAreaButton({ area }: Props) {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate("..");
  }, [navigate]);

  return (
    <DeleteButton
      title="Bereich löschen"
      description="Sind Sie sich sicher, dass Sie diesen Bereich löschen wollen?"
      object={area as any}
      afterDelete={goBack}
    />
  );
}

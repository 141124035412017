import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { FormControl, Input } from "native-base";
import { useCallback } from "react";
import {
  KeyboardType,
  NativeSyntheticEvent,
  TextInputEndEditingEventData,
} from "react-native";

type Props = {
  fieldPath: string;
  label?: string;
  doc: DocumentSnapshot<DocumentData>;
  updateDoc: (data: any) => Promise<void>;
  keyboardType?: KeyboardType;
  isDisabled?: boolean;
};

export default function TextFormInput({
  fieldPath,
  label,
  doc,
  updateDoc,
  keyboardType,
  isDisabled,
}: Props) {
  const defaultValue = doc.get(fieldPath);

  const onBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
      updateDoc({ [fieldPath]: e.nativeEvent.text });
    },
    [updateDoc, fieldPath]
  );

  return (
    <FormControl mb="1">
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Input
        defaultValue={defaultValue == null ? undefined : String(defaultValue)}
        onBlur={onBlur}
        bg="white"
        keyboardType={keyboardType}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
}

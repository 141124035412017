import React, { useCallback } from "react";
import { Button, Modal, Pressable, ScrollView, Stack, Text } from "native-base";
import { useAreaLogic } from "../../../hooks/useAreaLogic";
import { ObjectType } from "../../../hooks/useObjectType";
import { SuggestionType, useSuggestions } from "../../../hooks/useSuggestions";

type Props = {
  title: string;
  suggestionType: SuggestionType;
  onSelect: (suggestion?: string) => void;
  level?: number;
  objectType: ObjectType | null;
  rootAreaType?: string;
  onClose: () => void;
};

export default function WebSuggestionModal({
  title,
  suggestionType,
  onSelect,
  level,
  objectType,
  rootAreaType,
  onClose,
}: Props) {
  const onSelectItem = useCallback(
    (suggestion: string | undefined) => {
      onSelect(suggestion);
      onClose();
    },
    [onSelect, onClose]
  );

  const { suggestionsForLevel } = useAreaLogic(
    objectType,
    level!,
    rootAreaType
  );

  const [suggestions] = useSuggestions(
    suggestionType,
    "",
    suggestionsForLevel,
    rootAreaType
  );

  return (
    <Modal.Content maxWidth="400px">
      <Modal.CloseButton />

      <Modal.Header>{title}</Modal.Header>

      <Modal.Body>
        <ScrollView maxHeight={400}>
          <Stack>
            {suggestions.map((s) => {
              const currentSuggestion = s.get<string>("title");

              return (
                <Pressable
                  key={s.id}
                  py={1}
                  onPress={() => onSelectItem(currentSuggestion)}
                >
                  {({ isHovered }) => (
                    <Text color={isHovered ? "primary.400" : "black"}>
                      {currentSuggestion}
                    </Text>
                  )}
                </Pressable>
              );
            })}
          </Stack>
        </ScrollView>
      </Modal.Body>

      <Modal.Footer>
        <Button.Group space={2}>
          <Button variant="unstyled" colorScheme="coolGray" onPress={onClose}>
            Schliessen
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import { Spinner, Stack } from "native-base";
import Headline from "../typo/Headline";
import { useUser } from "../../hooks/useUser";
import TextFormInput from "../form/TextFormInput";
import ChangePassword from "./user/ChangePassword";
import ChangeStatus from "./user/ChangeStatus";
import TextAreaFormInput from "../form/TextAreaFormInput";
import SignatureUpload from "./user/SignatureUpload";

export default function EditUser() {
  const { userId } = useParams();

  const { user, updateUser, isLoading } = useUser(userId ?? "");

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Stack space={8} px={5} py={6}>
      <div>
        <Headline title="Benutzer bearbeiten" />

        <TextFormInput
          label="Name"
          fieldPath="name"
          doc={user as any}
          updateDoc={updateUser as any}
        />

        <TextAreaFormInput
          label="Berichtsverfasser"
          fieldPath="protocolAuthor"
          doc={user as any}
          updateDoc={updateUser as any}
        />

        <TextFormInput
          label="Telefonnummer"
          fieldPath="phone"
          doc={user as any}
          updateDoc={updateUser as any}
        />

        <SignatureUpload doc={user as any} updateDoc={updateUser as any} />
      </div>

      <div>
        <ChangePassword user={user as any} />
      </div>

      <div>
        <ChangeStatus user={user as any} />
      </div>
    </Stack>
  );
}

import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { groupBy, orderBy } from "lodash";
import { useMemo } from "react";
import { useFirestoreCollection } from "./useFirestoreCollection";

export const useCracks = (projectId: string, objectId: string) => {
  const [unsortedCracks, isLoading] = useFirestoreCollection(
    ["projects", projectId, "objects", objectId, "cracks"].join("/")
  );

  const allCracks = useMemo(
    () => orderBy(unsortedCracks, [(c) => c.get("createdAt")], ["asc"]),
    [unsortedCracks]
  );

  // root cracks (that are not a follow-up of another crack)
  const cracks = allCracks.filter((c) => !c.get("mainCrackId"));

  const cracksForAreaId = useMemo(() => {
    const lookup = new Map<string, typeof cracks>();

    const groupedByAreaId = groupBy(
      cracks,
      (c) => c.get<FirebaseFirestoreTypes.DocumentReference>("areaRef").id
    );

    for (const areaId in groupedByAreaId) {
      lookup.set(areaId, groupedByAreaId[areaId]);
    }

    return lookup;
  }, [cracks]);

  const allCracksForAreaId = useMemo(() => {
    const lookup = new Map<string, typeof allCracks>();

    const groupedByAreaId = groupBy(
      allCracks,
      (c) => c.get<FirebaseFirestoreTypes.DocumentReference>("areaRef").id
    );

    for (const areaId in groupedByAreaId) {
      lookup.set(areaId, groupedByAreaId[areaId]);
    }

    return lookup;
  }, [allCracks]);

  return [cracks, cracksForAreaId, allCracksForAreaId, isLoading] as const;
};

import React, { useCallback } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { FormControl, Select } from "native-base";

export type Option = {
  label: string;
  value: string;
};

type Props = {
  fieldPath: string;
  label?: string;
  placeholder?: string;
  options: Option[];
  doc: DocumentSnapshot<DocumentData>;
  updateDoc: (data: any) => Promise<void>;
};

export default function SelectFormInput({
  fieldPath,
  label,
  placeholder,
  options,
  doc,
  updateDoc,
}: Props) {
  const value = doc.get(fieldPath);

  const onValueChange = useCallback(
    (newValue: string) => {
      updateDoc({ [fieldPath]: newValue });
    },
    [updateDoc, fieldPath]
  );

  return (
    <FormControl mb="1">
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Select
        selectedValue={value}
        placeholder={placeholder}
        mt={1}
        onValueChange={onValueChange}
      >
        {options.map(({ label, value }) => (
          <Select.Item key={value} label={label} value={value} />
        ))}
      </Select>
    </FormControl>
  );
}

import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { isEmpty } from "lodash";
import { Box } from "native-base";
import {
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { AreaLevelLookup } from "../../../hooks/useAreas";
import AddAreaButton from "./AddAreaButton";
import DisplayAreas from "./DisplayAreas";
import { useAreaLogic } from "../../../hooks/useAreaLogic";
import { ObjectType } from "../../../hooks/useObjectType";
import AreaItem from "./AreaItem.web";
import { useSortAreas } from "../../../hooks/useSortAreas";
import { useAreas } from "../../../hooks/useAreas.web";

type Props = {
  area: FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  objectType: ObjectType | null;
  projectId: string;
  objectId: string;
  allAreas: FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[];
  levelForAreaId: AreaLevelLookup;
  addArea: (areaName: string, parentId: string | null) => void;
  cracksByAreaId: Map<
    string,
    FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
  >;
  isLoadingCracks: boolean;
  rootAreaType?: string;
};

export default function DisplayArea({
  area,
  objectType,
  projectId,
  objectId,
  allAreas,
  levelForAreaId,
  addArea,
  cracksByAreaId,
  isLoadingCracks,
  rootAreaType,
}: Props) {
  // TODO: Refactor this such that we don't have so much duplication
  const { id } = area;
  const { title } = area.data();

  const children = allAreas.filter((x) => x.get("parentId") === id);
  const level = levelForAreaId.get(id) ?? 1;
  const cracksCount = cracksByAreaId.get(id)?.length ?? 0;

  let rootAreaTypeForChilden = rootAreaType;
  if (!rootAreaTypeForChilden) {
    rootAreaTypeForChilden = String(title).match(/aussen/i)
      ? "outdoor"
      : "indoor";
  }

  const { canAddSubareas, canAddCracks } = useAreaLogic(
    objectType,
    level,
    rootAreaType
  );

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { areas, updateAreaSorting } = useAreas(projectId, objectId);

  const { areasToSort } = useSortAreas(
    children as any,
    areas as any,
    updateAreaSorting
  );

  return (
    <div ref={setNodeRef} style={style}>
      <Box key={id} pl={level >= 2 ? 3.5 : 0}>
        <AreaItem
          areaId={id}
          title={title}
          projectId={projectId}
          objectId={objectId}
          level={level}
          cracksCount={cracksCount}
          canAddCracks={canAddCracks}
          isLoadingCracks={isLoadingCracks}
          rootAreaTypeForChilden={rootAreaTypeForChilden}
          attributes={attributes}
          listeners={listeners}
        />

        {!isEmpty(children) && (
          <SortableContext
            items={areasToSort}
            strategy={verticalListSortingStrategy}
          >
            <DisplayAreas
              objectType={objectType}
              projectId={projectId}
              objectId={objectId}
              areas={children}
              allAreas={allAreas}
              levelForAreaId={levelForAreaId}
              parentId={id}
              addArea={addArea}
              cracksByAreaId={cracksByAreaId}
              isLoadingCracks={isLoadingCracks}
              rootAreaType={rootAreaTypeForChilden}
            />
          </SortableContext>
        )}

        {canAddSubareas && (
          <Box ml={3.5} mb={4} mt={2}>
            <AddAreaButton
              onAddAreaWithName={(areaName) =>
                areaName && addArea(areaName, id)
              }
              level={level + 1}
              rootAreaType={rootAreaTypeForChilden}
              objectType={objectType}
            />
          </Box>
        )}
      </Box>
    </div>
  );
}

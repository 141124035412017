import React from "react";
import { Link } from "react-router-dom";
import { ScrollView } from "native-base";
import { Protocol } from "../WebAccess";
import VermflexLogo from "../../../assets/vermflex-logo.png";

type Props = {
  protocols: Protocol[];
};

export default function WebProtocolSelector({ protocols }: Props) {
  return (
    <ScrollView height="100vh">
      <div className="webprotocol">
        <img src={VermflexLogo} width={300} alt="vermflex" />
        <h1>Rissprotokolle</h1>
        {protocols.map((protocol, idx) => {
          const { owner, constructionSite, protocolPDFUrl } = protocol;

          return (
            <div key={protocolPDFUrl}>
              <h2>{constructionSite}</h2>
              <div className="with-line-breaks">{owner}</div>

              <div>
                <Link to={`${idx}`}>Zum Webprotokoll</Link>
              </div>

              <div>
                <a
                  href={protocolPDFUrl}
                  download={`${constructionSite}.pdf`}
                  target="_blank"
                >
                  Als PDF herunterladen
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </ScrollView>
  );
}

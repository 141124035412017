import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { FormControl, HStack, Text } from "native-base";
import StateIndicator from "../shared/StateIndicator";
import { useProjectDetailState } from "../../hooks/useProjectDetailState";

type Props = {
  project: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateProject: (data: any) => Promise<void>;
};

export default function ProjectDetailState({ project, updateProject }: Props) {
  const projectDetailStates = useProjectDetailState(project, updateProject);

  return (
    <FormControl mb="1">
      <FormControl.Label>Status</FormControl.Label>

      {projectDetailStates &&
        projectDetailStates.map(({ stateText, stateColor }, idx) => (
          <HStack space={2} key={idx}>
            <StateIndicator stateColor={stateColor} />
            <Text>{stateText}</Text>
          </HStack>
        ))}
    </FormControl>
  );
}

import {
  collection,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../App.web";

export const usePhotos = (
  collectionId: "overviewPhotos" | "overviewMaps",
  projectId: string,
  objectId: string
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [photos, setPhotos] = useState<QueryDocumentSnapshot<DocumentData>[]>(
    []
  );

  useEffect(() => {
    setIsLoading(true);

    const q = query(
      collection(
        firestore,
        ["projects", projectId, "objects", objectId, collectionId].join("/")
      ),
      orderBy("capturedAt")
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setPhotos(snapshot.docs);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [setIsLoading, setPhotos, projectId, objectId]);

  return [photos, isLoading] as const;
};

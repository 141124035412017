import { useContext } from "react";
import { CurrentUserContext } from "../App";
import { useUser } from "./useUser";

export const useCurrentUser = () => {
  const currentUser = useContext(CurrentUserContext);
  const { user, isLoading } = useUser(currentUser?.email ?? "");

  return [user, isLoading] as const;
};

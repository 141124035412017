import React, { useCallback, useState } from "react";
import { Button } from "native-base";
import { auth } from "../../../App.web";

export default function LogoutButton() {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logout = useCallback(() => {
    (async () => {
      setIsLoggingOut(true);
      try {
        await auth.signOut();
      } finally {
        setIsLoggingOut(false);
      }
    })();
  }, [setIsLoggingOut]);

  return (
    <Button onPress={logout} isLoading={isLoggingOut}>
      Ausloggen
    </Button>
  );
}

import React from "react";
import { Text } from "native-base";

type Props = {
  children: React.ReactNode;
};

export default function TableHeadline({ children }: Props) {
  return (
    <th>
      <Text fontWeight="bold">{children}</Text>
    </th>
  );
}

import { useCallback } from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { FormControl, Switch } from "native-base";

type Props = {
  fieldPath: string;
  label?: string;
  checkboxLabel?: string;
  doc: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateDoc: (data: any) => Promise<void>;
  isDisabled?: boolean;
};

export default function CheckboxFormInput({
  fieldPath,
  label,
  doc,
  updateDoc,
  isDisabled,
}: Props) {
  const defaultValue = doc.get(fieldPath);

  const onToggle = useCallback(
    (newValue: boolean) => {
      console.log("Onchange is called", newValue);

      updateDoc({ [fieldPath]: newValue });
    },
    [updateDoc, fieldPath]
  );

  return (
    <FormControl mb="1">
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <Switch
        isChecked={Boolean(defaultValue ?? false)}
        onToggle={onToggle}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
}

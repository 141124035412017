import React from "react";
import { Box } from "native-base";
import { StateColor, stateColorMapping } from "../../hooks/useProjectState";

type Props = {
  stateColor?: StateColor;
};

export default function StateIndicator({ stateColor }: Props) {
  return (
    <Box
      w={3}
      h={3}
      rounded="full"
      bg={stateColor ? stateColorMapping[stateColor] : "gray.50"}
      mt={1}
    />
  );
}

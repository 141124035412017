import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useCallback } from "react";
import { firestore } from "../App.web";
import { useFirestoreCollection } from "./useFirestoreCollection";

export const useCrackMarks = (
  projectId: string,
  objectId: string,
  crackId: string
) => {
  const collectionPath = [
    "projects",
    projectId,
    "objects",
    objectId,
    "cracks",
    crackId,
    "crackMarks",
  ].join("/");

  const [crackMarks, isLoading] = useFirestoreCollection(collectionPath);

  const addCrackMark = useCallback(
    async (x: number, y: number, size: number) => {
      return addDoc(collection(firestore, collectionPath), {
        x,
        y,
        size,
        createdAt: Timestamp.now(),
      });
    },
    []
  );

  return { crackMarks, isLoading, addCrackMark };
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { Text, HStack, Pressable, Icon } from "native-base";
import { Feather } from "@expo/vector-icons";
import { DraggableAttributes } from "@dnd-kit/core";

type Props = {
  areaId: string;
  title: string;
  projectId: string;
  objectId: string;
  level: number;
  cracksCount: number;
  canAddCracks: boolean;
  isLoadingCracks: boolean;
  rootAreaTypeForChilden: string;
  attributes: DraggableAttributes;
  listeners?: any;
};

export default function AreaItem({
  areaId,
  title,
  projectId,
  objectId,
  level,
  cracksCount,
  canAddCracks,
  isLoadingCracks,
  rootAreaTypeForChilden,
  attributes,
  listeners,
}: Props) {
  const navigate = useNavigate();

  const levelToFontSize: any = {
    1: "lg",
    2: "md",
  };

  return (
    <HStack>
      <Pressable
        onPress={() => {
          navigate(
            ["/admin/projects", projectId, objectId, areaId].join("/") +
              `?rootAreaType=${rootAreaTypeForChilden}&level=${level}&canAdd=${canAddCracks}`
          );
        }}
        flexGrow={1}
      >
        {({ isHovered }) => (
          <HStack py={2.5} mr={4}>
            <Text
              fontSize={levelToFontSize[level] ?? "sm"}
              color={isHovered ? "primary.400" : "black"}
            >
              {title}
            </Text>

            {!isLoadingCracks && canAddCracks && (
              <Text color={isHovered ? "primary.400" : "gray.500"} ml={2}>
                {cracksCount} {cracksCount === 1 ? "Riss" : "Risse"}
              </Text>
            )}
          </HStack>
        )}
      </Pressable>

      <span {...attributes} {...listeners} style={{ cursor: "grab" }}>
        <Icon as={Feather} name="move" color="gray.500" />
      </span>
    </HStack>
  );
}

import { useCallback, useState } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useToast } from "native-base";
import Constants from "expo-constants";
import { useUserIDToken } from "./useUserIDToken.web";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

export const useCreateFollowUpInspection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const getUserIDToken = useUserIDToken();

  const createFollowUpInspection = useCallback(
    async (object: DocumentSnapshot<DocumentData>) => {
      try {
        setIsLoading(true);

        const objectId = object.id;
        const projectId = object.ref.parent.parent!.id;

        const authToken = await getUserIDToken();

        const response = await fetch(
          `${BACKEND_API_BASE_URL}/protocol/create-follow-up`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              authToken,
              projectId,
              objectId,
            }),
          },
        );

        const json = await response.json();

        const {
          success,
          projectId: newProjectId,
          objectId: newObjectId,
        } = json;

        if (!success) {
          throw new Error(JSON.stringify(json));
        }

        // we are not using navigate here because that does not work well with scrolling to the top of the scrollview
        location.href = `/admin/projects/${newProjectId}/${newObjectId}`;
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setIsLoading(false);
    },
    [setIsLoading, toast, getUserIDToken],
  );

  return [createFollowUpInspection, isLoading] as const;
};

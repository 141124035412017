import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { FormControl } from "native-base";
import ObjectState from "./ObjectState";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
};

export default function ObjectStateAsFormField({ object }: Props) {
  return (
    <FormControl mb="1">
      <FormControl.Label>Status</FormControl.Label>

      <ObjectState object={object} />
    </FormControl>
  );
}

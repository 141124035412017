import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import TextFormInput from "../../form/TextFormInput.web";

export const DEFAULT_SALUTATION = "Sehr geehrter Herr, sehr geehrte Frau";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function SalutationField({ object, updateObject }: Props) {
  const salutation = object.get("salutation");

  useEffect(() => {
    if (isEmpty(salutation)) {
      updateObject({ salutation: DEFAULT_SALUTATION });
    }
  }, [salutation]);

  return (
    <div>
      <TextFormInput
        label="Anrede"
        fieldPath="salutation"
        doc={object}
        updateDoc={updateObject}
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { firestore } from "../App.web";

export const useProjectWebAccessAccounts = (projectId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const projectRef = doc(firestore, ["projects", projectId].join("/"));

    const q = query(
      collection(firestore, "webAccessAccounts"),
      where("projectRef", "==", projectRef)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setAccounts(snapshot.docs);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [setIsLoading, setAccounts, projectId]);

  return [accounts, isLoading] as const;
};

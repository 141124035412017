import React, { useCallback } from "react";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { Feather } from "@expo/vector-icons";
import DisplayCrackListItem from "./DisplayCrackListItem.web";
import {
  isFollowUpForCrackId,
  isNoVisibleChange,
  isOriginalForCrackId,
} from "../../../helper/crack-helper";
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
} from "native-base";
import Subsection from "../../typo/Subsection";
import { useCreateCrack } from "../../../hooks/useCreateCrack";

type Props = {
  crack: DocumentSnapshot<DocumentData>;
  allCracks: DocumentSnapshot<DocumentData>[];
  photosByCrackId: Map<string, QueryDocumentSnapshot<DocumentData>[]>;
  projectId: string;
  objectId: string;
  areaId: string;
  rootAreaType?: string;
  isFollowUpInspection: boolean;
};

export default function CrackListItem({
  crack,
  allCracks,
  photosByCrackId,
  projectId,
  objectId,
  areaId,
  rootAreaType,
  isFollowUpInspection,
}: Props) {
  const createCrack = useCreateCrack(projectId, objectId, areaId);

  const noVisibleChange = useCallback(() => {
    createCrack(crack.id, "followUp", true);
  }, [createCrack]);

  const removeCrack = useCallback(
    async (crack: DocumentSnapshot<DocumentData>) => {
      await deleteDoc(crack.ref);
    },
    []
  );

  const captureCrack = useCallback(async () => {
    await createCrack(crack.id, "followUp");
  }, [createCrack]);

  const data = crack.data();

  if (!data) {
    return null;
  }

  if (isFollowUpInspection) {
    const originalCrack = allCracks.find(isOriginalForCrackId(crack.id));
    const followUpCrack = allCracks.find(isFollowUpForCrackId(crack.id));

    return (
      <Stack>
        {originalCrack ? (
          <DisplayCrackListItem
            crack={originalCrack}
            photosByCrackId={photosByCrackId}
            projectId={projectId}
            objectId={objectId}
            areaId={areaId}
            rootAreaType={rootAreaType}
            isEditable={false}
          />
        ) : (
          <Text>Nicht vorhanden</Text>
        )}

        <Box h={4} />
        <Subsection title="Nachkontrolle" />
        <Box h={4} />

        {followUpCrack ? (
          isNoVisibleChange(followUpCrack) ? (
            <HStack alignItems="center" space="4">
              <HStack alignItems="center" space="1">
                <Icon as={Feather} name="check" color="primary.400" />

                <Text>Keine sichtbaren Änderungen</Text>
              </HStack>

              <IconButton
                variant="outline"
                size="sm"
                onPress={() => removeCrack(followUpCrack)}
                colorScheme="danger"
                icon={<Icon as={Feather} name="trash" colorScheme="danger" />}
              />
            </HStack>
          ) : (
            <DisplayCrackListItem
              crack={followUpCrack}
              photosByCrackId={photosByCrackId}
              projectId={projectId}
              objectId={objectId}
              areaId={areaId}
              rootAreaType={rootAreaType}
              isEditable
            />
          )
        ) : (
          <HStack flex={1} space="4">
            <Button onPress={noVisibleChange}>
              Keine sichtbaren Änderungen
            </Button>

            <Button onPress={captureCrack} colorScheme="danger">
              Riss erfassen
            </Button>
          </HStack>
        )}
      </Stack>
    );
  }

  return (
    <DisplayCrackListItem
      crack={crack}
      photosByCrackId={photosByCrackId}
      projectId={projectId}
      objectId={objectId}
      areaId={areaId}
      rootAreaType={rootAreaType}
      isEditable
    />
  );
}

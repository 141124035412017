import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import TextAreaFormInput from "../../form/TextAreaFormInput.web";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function ProtocolAuthorField({ object, updateObject }: Props) {
  const [user, isLoading] = useCurrentUser();

  const protocolAuthor = object.get("protocolAuthor");

  useEffect(() => {
    if (!isLoading && isEmpty(protocolAuthor)) {
      const userProtocolAuthor = (user as any).get("protocolAuthor");

      if (!isEmpty(userProtocolAuthor)) {
        updateObject({ protocolAuthor: userProtocolAuthor });
      }
    }
  }, [isLoading, user, protocolAuthor]);

  return (
    <div>
      <TextAreaFormInput
        label="Berichtverfasser"
        fieldPath="protocolAuthor"
        doc={object}
        updateDoc={updateObject}
      />
    </div>
  );
}

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { CurrentUserContext } from "../App";

export default function RedirectBasedOnAuth() {
  const currentUser = useContext(CurrentUserContext);

  if (currentUser) {
    return <Navigate to="/admin/projects" replace />;
  } else {
    return <Navigate to="/admin/login" replace />;
  }
}

import { useCallback } from "react";
import Constants from "expo-constants";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Button, Stack, useToast } from "native-base";
import { Formik, FormikHelpers } from "formik";
import { object, string, ref } from "yup";
import { useUserIDToken } from "../../../hooks/useUserIDToken.web";
import Subsection from "../../typo/Subsection";
import { formikErrorMessages } from "../../../helper/formik-helper";
import FormikTextFormInput from "../../form/FormikTextFormInput";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

type Props = {
  user: DocumentSnapshot<DocumentData>;
};

const initialValues = {
  newPassword: "",
  passwordConfirmation: "",
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  newPassword: string().required(formikErrorMessages.required),
  passwordConfirmation: string()
    .oneOf([ref("newPassword")], formikErrorMessages.notMatching)
    .required(formikErrorMessages.required),
});

export default function ChangePassword({ user }: Props) {
  const toast = useToast();
  const getUserIDToken = useUserIDToken();

  const userData = user.data();

  if (!userData) {
    return null;
  }

  const { eMail } = userData;

  const changePassword = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { newPassword } = values;
      const { setSubmitting, resetForm } = formikHelpers;

      const authToken = await getUserIDToken();

      try {
        const response = await fetch(
          `${BACKEND_API_BASE_URL}/users/changePassword`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              authToken,
              eMail,
              newPassword,
            }),
          }
        );

        const json = await response.json();

        const { success, error } = json;

        if (success) {
          toast.show({
            placement: "top",
            title: "Das Passwort wurde erfolgreich geändert",
          });

          resetForm();
        } else {
          if (error) {
            toast.show({
              placement: "top",
              title: error,
            });
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setSubmitting(false);
    },
    [toast]
  );

  return (
    <Stack space={4}>
      <Subsection title="Passwort ändern" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={changePassword}
      >
        {({ isSubmitting, submitForm }) => (
          <Stack>
            <FormikTextFormInput
              fieldPath="newPassword"
              label="Neues Passwort"
              type="password"
            />

            <FormikTextFormInput
              fieldPath="passwordConfirmation"
              label="Passwort bestätigen"
              type="password"
            />

            <Button onPress={submitForm} isLoading={isSubmitting} mt={8}>
              Passwort ändern
            </Button>
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}

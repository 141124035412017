import {
  collection,
  DocumentData,
  QueryDocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../App.web";

export const useFirestoreCollection = (collectionPath: string) => {
  const [isLoading, setIsLoading] = useState(true);

  const [docs, setDocs] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);

  useEffect(() => {
    setIsLoading(true);

    const unsub = onSnapshot(
      collection(firestore, collectionPath),
      (snapshot) => {
        setDocs(snapshot.docs);
        setIsLoading(false);
      }
    );

    return () => {
      unsub();
    };
  }, [setIsLoading, setDocs, collectionPath]);

  return [docs, isLoading] as const;
};

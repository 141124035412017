import React, { useCallback, useState } from "react";
import { Button, Icon } from "native-base";
import { Feather } from "@expo/vector-icons";
import { deleteDoc, DocumentData, DocumentSnapshot } from "firebase/firestore";
import ConfirmDeletion from "./ConfirmDeletion";

type Props = {
  title: string;
  description: string;
  object: DocumentSnapshot<DocumentData>;
  afterDelete?: () => void;
  noMargin?: boolean;
  withIcon?: boolean;
};

export default function DeleteButton({
  title,
  description,
  object,
  afterDelete,
  noMargin,
  withIcon,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const onButtonPress = useCallback(() => setIsOpen(true), [setIsOpen]);

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const onDelete = useCallback(async () => {
    setIsOpen(false);

    await deleteDoc(object.ref);

    afterDelete && afterDelete();
  }, [setIsOpen, afterDelete, object]);

  return (
    <>
      <Button
        onPress={onButtonPress}
        variant="outline"
        colorScheme="danger"
        mt={noMargin ? undefined : "12"}
        alignSelf="flex-start"
        startIcon={withIcon ? <Icon as={Feather} name="trash" /> : undefined}
      >
        {title}
      </Button>

      <ConfirmDeletion
        isOpen={isOpen}
        title={title}
        description={description}
        onDelete={onDelete}
        onClose={onClose}
      />
    </>
  );
}

import { useCallback } from "react";
import { useCreateWebAccess } from "./useCreateWebAccess";
import { useGetFirebaseObject } from "./useGetFirebaseObject";
import { useUpdateFirebaseObject } from "./useUpdateFirebaseObject";

const randomLetter = () =>
  String.fromCharCode(Math.floor(Math.random() * 26) + 65);

const randomNumber = () => Math.floor(Math.random() * 10);

const generateRandomWebAccessId = () => {
  const code = [];
  code.push(randomLetter());
  code.push(randomLetter());
  code.push(randomNumber());
  code.push(randomNumber());
  code.push(randomNumber());

  return code.join("");
};

const generateRandomAccessCode = () => {
  const code = [];
  for (let i = 0; i < 6; i++) {
    code.push(randomNumber());
  }

  return code.join("");
};

export const useCreateInitialWebAccess = (
  projectId: string,
  objectId: string
) => {
  const objectDocPath = ["projects", projectId, "objects", objectId].join("/");
  const getObject = useGetFirebaseObject(objectDocPath);
  const updateObject = useUpdateFirebaseObject(objectDocPath);
  const createWebAccess = useCreateWebAccess();

  return useCallback(async () => {
    const objectData = await getObject();

    if (!objectData) {
      return;
    }

    let webAccessId = objectData.webAccessId;

    if (!webAccessId) {
      webAccessId = generateRandomWebAccessId();
      await updateObject({ webAccessId });

      const code = generateRandomAccessCode();

      // Assign it to the object
      await createWebAccess(projectId, objectId, "", code);
    }
  }, [createWebAccess, projectId, objectId, getObject]);
};

import { useFirebaseObject } from "./useFirebaseObject";

export const objectTypeOptions: {
  label: string;
  value: ObjectType;
}[] = [
  {
    label: "Einfamilienhaus",
    value: "familyHome",
  },
  {
    label: "Mehrfamilienhaus",
    value: "apartmentHouse",
  },
  {
    label: "Strasse",
    value: "street",
  },
];

export const useObject = (projectId: string, objectId: string) => {
  const [object, updateObject, isLoading] = useFirebaseObject(
    ["projects", projectId, "objects", objectId].join("/")
  );

  return { object, updateObject, isLoading };
};

import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { Stack } from "native-base";
import React from "react";
import DateFormInput from "../../form/DateFormInput";
import TextAreaFormInput from "../../form/TextAreaFormInput";
import Subsection from "../../typo/Subsection";
import CreateProtocolButton from "./CreateProtocolButton";
import DownloadProtocolButton from "./DownloadProtocolButton";
import CreateRetourLetterButton from "./CreateRetourLetterButton";
import CreateCoverLetterButton from "./CreateCoverLetterButton";
import CreateFinalCoverLetterButton from "./CreateFinalCoverLetterButton";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function ObjectProtocolTimestamps({
  object,
  updateObject,
}: Props) {
  return (
    <Stack space={10}>
      <Stack space={4}>
        <Subsection title="Protokoll herunterladen" />

        <DownloadProtocolButton
          format="docx"
          url={object.get<string>("protocolDOCXUrl")}
        />

        <DownloadProtocolButton
          format="pdf"
          url={object.get<string>("protocolPDFUrl")}
        />

        <CreateProtocolButton object={object} regenerateProtocol />

        <CreateRetourLetterButton object={object} />

        <CreateCoverLetterButton object={object as any} />

        <CreateFinalCoverLetterButton object={object as any} />
      </Stack>

      <Stack space={2}>
        <Subsection title="Status Protokoll" />

        <DateFormInput
          label="An Eigentümer verschickt"
          fieldPath="sentToOwnerAt"
          doc={object}
          updateDoc={updateObject}
        />

        <DateFormInput
          label="Von Eigentümer zurück"
          fieldPath="returnedFromOwnerAt"
          doc={object}
          updateDoc={updateObject}
        />

        <DateFormInput
          label="An Auftraggeber verschickt"
          fieldPath="sentToClientAt"
          doc={object}
          updateDoc={updateObject}
        />

        <DateFormInput
          label="Von Auftraggeber zurück"
          fieldPath="returnedFromClientAt"
          doc={object}
          updateDoc={updateObject}
        />

        <DateFormInput
          label="Final an Eigentümer verschickt"
          fieldPath="sentFinalToOwnerAt"
          doc={object}
          updateDoc={updateObject}
        />

        <DateFormInput
          label="Manuelle Schliessung"
          fieldPath="manualClosureAt"
          doc={object}
          updateDoc={updateObject}
        />

        <TextAreaFormInput
          label="Kommentar"
          fieldPath="manualClosureNote"
          doc={object}
          updateDoc={updateObject}
        />
      </Stack>
    </Stack>
  );
}

import React, { useCallback } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Button, Icon } from "native-base";
import { Feather } from "@expo/vector-icons";
import isEmpty from "lodash/isEmpty";
import { useCreateLetter } from "../../../hooks/useCreateLetter";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useAuthorFields } from "../../../hooks/useAuthorFields";
import { DEFAULT_SALUTATION } from "../../web/components/SalutationField";

type Props = {
  appointment: DocumentSnapshot<DocumentData>;
  object: DocumentSnapshot<DocumentData>;
  project: DocumentSnapshot<DocumentData>;
};

export default function CreateAppointmentLetterButton({
  appointment,
  object,
  project,
}: Props) {
  const {
    appointmentDate,
    duration,
    salutation: appointmentSalutation,
    address: appointmentAddress,
  } = appointment?.data() || {};

  const [user] = useCurrentUser();
  const [createLetter, isLoading] = useCreateLetter("appointment");
  const author = useAuthorFields(user);

  const constructionSite = project.get("constructionSite") ?? "";
  const property = object.get("propertyNumber") ?? "";

  let address = object.get("owner") ?? "";
  if (!isEmpty(appointmentAddress)) {
    address = appointmentAddress;
  }

  let salutation = DEFAULT_SALUTATION;
  if (!isEmpty(appointmentSalutation)) {
    salutation = appointmentSalutation;
  } else if (!isEmpty(object.get("salutation"))) {
    salutation = object.get("salutation");
  }

  const createAppointmentLetter = useCallback(() => {
    const payload = {
      address,
      salutation,
      mainText: `Aufgrund des Neubaus in ${constructionSite} sind wir beauftragt worden, unter anderem an den Objekten auf der Parzelle ${property} ein Rissprotokoll zu erstellen. Das Ziel der Rissaufnahmen ist die vorgängige Beweissicherung. Diese sollen den Zustand der angrenzenden Gebäude und Anlagen festhalten. Es werden die direkt visuell feststellbaren Risse festgehalten. Diese Grundlage, erstellt vor Baubeginn, dient der allfälligen Schadensbeurteilung und Schadensbehebung. Somit brauchen wir Zugang zu jedem Raum, um die Fotos zu erstellen.
Anbei der Termin, wann die Aufnahmen geplant sind. Wir danken Ihnen, wenn Sie am vorgegebenen Termin vor Ort sind und uns den Zugang zu Ihren Räumlichkeiten gewähren.`,
      appointmentDate: appointmentDate?.toDate()?.toJSON() ?? null,
      duration: duration ?? "",
      author,
    };

    createLetter(payload);
  }, [
    createLetter,
    constructionSite,
    property,
    address,
    appointmentSalutation,
    salutation,
    author,
    appointmentDate,
    duration,
  ]);

  return (
    <Button
      onPress={createAppointmentLetter}
      variant="solid"
      isLoading={isLoading}
      startIcon={<Icon as={Feather} name="file-text" />}
    >
      Terminbrief erstellen
    </Button>
  );
}

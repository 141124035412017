import { useCallback, useState } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Button, Icon, Modal, Stack, useToast } from "native-base";
import { Feather } from "@expo/vector-icons";
import { Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import FormikTextFormInput from "../form/FormikTextFormInput";
import { formikErrorMessages } from "../../helper/formik-helper";
import FormikTextAreaFormInput from "../form/FormikTextAreaFormInput";
import { useUserIDToken } from "../../hooks/useUserIDToken.web";
import Constants from "expo-constants";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

type FormValues = {
  mailTo: string;
  subject: string;
  body: string;
};

const validationSchema = object().shape({
  mailTo: string()
    .email(formikErrorMessages.eMail)
    .required(formikErrorMessages.required),
  subject: string().required(formikErrorMessages.required),
  body: string().required(formikErrorMessages.required),
});

type Props = {
  webAccessId: string;
  webAccess: DocumentSnapshot<DocumentData>;
};

export default function ObjectWebAccessSendMail({
  webAccessId,
  webAccess,
}: Props) {
  const toast = useToast();
  const getUserIDToken = useUserIDToken();

  const [isOpen, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), [setOpen]);
  const closeModal = useCallback(() => setOpen(false), [setOpen]);

  const sendMail = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { mailTo, subject, body } = values;
      const { setSubmitting } = formikHelpers;

      try {
        const authToken = await getUserIDToken();

        const response = await fetch(`${BACKEND_API_BASE_URL}/mail/webaccess`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mailTo,
            subject,
            body,
            authToken,
          }),
        });

        const json = await response.json();

        const { success } = json;

        if (!success) {
          throw new Error(JSON.stringify(json));
        }

        toast.show({
          placement: "top",
          title: "Die E-Mail wurde erfolgreich gesendet.",
        });

        closeModal();
      } catch (e) {
        console.error(e);
        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setSubmitting(false);
    },
    [closeModal]
  );

  const initialValues = {
    mailTo: webAccess.get("eMail") ?? "",
    subject: "Zugangsdaten Rissprotokoll",
    body: `Sehr geehrte Damen und Herren

Anbei finden die Zugangsdaten zu Ihrem Rissprotokoll.

Link: https://rissprotokoll.vermflex.ch/${webAccessId}
Projekt ID: ${webAccessId}
Zugangscode: ${webAccess.get("code")}


Freundliche Grüsse

Vermflex GmbH`,
  };

  return (
    <>
      <Button leftIcon={<Icon as={Feather} name="send" />} onPress={openModal}>
        Zugangsdaten per Mail senden
      </Button>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <Modal.Content maxH="80vh" minW="80vw">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={sendMail}
          >
            {({ isSubmitting, submitForm }) => (
              <>
                <Modal.CloseButton />

                <Modal.Header>Zugangsdaten per Mail senden</Modal.Header>

                <Modal.Body>
                  <Stack>
                    <FormikTextFormInput fieldPath="mailTo" label="An" />

                    <FormikTextFormInput fieldPath="subject" label="Betreff" />

                    <FormikTextAreaFormInput
                      fieldPath="body"
                      label="Nachricht"
                    />
                  </Stack>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    onPress={submitForm}
                    leftIcon={<Icon as={Feather} name="send" />}
                    alignSelf="flex-end"
                    isLoading={isSubmitting}
                  >
                    E-Mail senden
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    </>
  );
}

import React from "react";
import { Box, HStack, Icon, Pressable, Stack, Text } from "native-base";
import { Feather } from "@expo/vector-icons";
import useToggle from "react-use/lib/useToggle";
import { lowerFirst, upperFirst } from "lodash";
import Subsection from "../../typo/Subsection";
import CrackOverviewPhoto from "./CrackOverViewPhoto.web";
import CrackDetailPhotosList from "./CrackDetailPhotosList";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import TextFormInput from "../../form/TextFormInput.web";
import { useCrack } from "../../../hooks/useCrack";
import SuggestionFormInput from "../../form/SuggestionFormInput";
import CheckboxFormInput from "../../form/CheckboxFormInput";
import DeleteButton from "../../shared/DeleteButton.web";
import TwoCols from "../../web/shared/TwoCols";
import SegmentedControlFormInput from "../../form/SegmentedControlFormInput";

type Props = {
  crack: DocumentSnapshot<DocumentData>;
  photosByCrackId: Map<string, QueryDocumentSnapshot<DocumentData>[]>;
  projectId: string;
  objectId: string;
  areaId: string;
  rootAreaType?: string;
  isEditable: boolean;
};

export default function DisplayCrackListItem({
  crack,
  photosByCrackId,
  projectId,
  objectId,
  areaId,
  rootAreaType,
  isEditable,
}: Props) {
  const { updateCrack } = useCrack(projectId, objectId, crack.id);

  const [isDetailPhotoPanelExpanded, toggleDetailPhotoPanel] = useToggle(false);

  const data = crack.data();

  if (!data) {
    return null;
  }

  const { title, description, position } = data;

  const subline = upperFirst(
    `${description ?? ""} ${lowerFirst(position ?? "")}`
  );

  const photos = photosByCrackId.get(crack.id) ?? [];
  const detailPhotos = photos.filter(
    (p) => p.get("photoType") === "detailPhoto"
  );

  return (
    <Stack>
      <Subsection title={title} />

      <Text>{subline}</Text>

      <HStack space={4} mt={2}>
        <Box w="400">
          <CrackOverviewPhoto
            crack={crack as any}
            photosByCrackId={photosByCrackId as any}
            projectId={projectId}
            objectId={objectId}
            areaId={areaId}
            isDisabled={!isEditable}
          />
        </Box>

        <TwoCols
          flexGrow={1}
          leftContent={
            <>
              <HStack alignItems="flex-end" space={4}>
                <Box flexGrow={1}>
                  <TextFormInput
                    label="Länge"
                    fieldPath="length"
                    doc={crack}
                    updateDoc={updateCrack as any}
                    keyboardType="decimal-pad"
                    isDisabled={!isEditable}
                  />
                </Box>
                <Box w="180" mb={1}>
                  <SegmentedControlFormInput
                    fieldPath="lengthUnit"
                    options={["mm", "cm", "m"]}
                    defaultOptionIndex={1}
                    doc={crack as any}
                    updateDoc={updateCrack as any}
                    isDisabled={!isEditable}
                  />
                </Box>
              </HStack>

              <SuggestionFormInput
                label="Breite"
                fieldPath="width"
                suggestionType="width"
                doc={crack as any}
                updateDoc={updateCrack as any}
                isDisabled={!isEditable}
              />

              <SuggestionFormInput
                label="Schaden"
                fieldPath="description"
                suggestionType="damage"
                doc={crack as any}
                updateDoc={updateCrack as any}
                isDisabled={!isEditable}
              />

              <CheckboxFormInput
                label="In Protokoll ausblenden"
                fieldPath="isHiddenInProtocol"
                doc={crack as any}
                updateDoc={updateCrack as any}
                isDisabled={!isEditable}
              />
            </>
          }
          rightContent={
            <>
              <SuggestionFormInput
                label="Rissbeschrieb"
                fieldPath="title"
                suggestionType="crack"
                rootAreaType={rootAreaType}
                doc={crack as any}
                updateDoc={updateCrack as any}
                isDisabled={!isEditable}
              />

              <SuggestionFormInput
                label="Detailposition"
                fieldPath="position"
                suggestionType="position"
                doc={crack as any}
                updateDoc={updateCrack as any}
                isDisabled={!isEditable}
              />

              {isEditable && (
                <DeleteButton
                  title="Riss löschen"
                  description="Sind Sie sich sicher, dass Sie diesen Riss löschen wollen?"
                  object={crack as any}
                  noMargin
                />
              )}
            </>
          }
        />
      </HStack>

      <Pressable
        onPress={toggleDetailPhotoPanel}
        _pressed={{ opacity: 0.5 }}
        hitSlop={5}
        mt={4}
      >
        <HStack
          space={1}
          bg="primary.400"
          borderRadius="full"
          px={2.5}
          py={1}
          alignSelf="flex-start"
        >
          <Text fontSize="xs" color="white">
            {detailPhotos.length}{" "}
            {detailPhotos.length === 1 ? "Detailfoto" : "Detailfotos"}
          </Text>

          <Icon
            as={Feather}
            name={isDetailPhotoPanelExpanded ? "chevron-up" : "chevron-down"}
            size="md"
            color="white"
          />
        </HStack>
      </Pressable>

      {isDetailPhotoPanelExpanded && (
        <CrackDetailPhotosList
          crack={crack as any}
          detailPhotos={detailPhotos as any}
          projectId={projectId}
          objectId={objectId}
          areaId={areaId}
          isDisabled={!isEditable}
        />
      )}
    </Stack>
  );
}

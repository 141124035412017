import React, { useCallback } from "react";
import { Button, Stack } from "native-base";
import { Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import FormikTextFormInput from "../form/FormikTextFormInput";
import { formikErrorMessages } from "../../helper/formik-helper";
import { useCreateWebAccess } from "../../hooks/useCreateWebAccess";
import Subsection from "../typo/Subsection";

const initialValues = {
  eMail: "",
  code: "",
};

type FormValues = typeof initialValues;

const validationSchema = object().shape({
  eMail: string().email(formikErrorMessages.eMail),
  code: string().required(formikErrorMessages.required),
});

type Props = {
  projectId: string;
  objectId?: string;
};

export default function CreateWebAccess({ projectId, objectId }: Props) {
  const createWebAccess = useCreateWebAccess();

  const createUser = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { eMail, code } = values;
      const { setSubmitting, resetForm } = formikHelpers;

      await createWebAccess(projectId, objectId, eMail, code);

      resetForm();
      setSubmitting(false);
    },
    [createWebAccess]
  );

  return (
    <Stack py={6}>
      <Subsection title="Zugang hinzufügen" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createUser}
      >
        {({ isSubmitting, submitForm }) => (
          <Stack>
            <FormikTextFormInput fieldPath="eMail" label="E-Mail" />

            <FormikTextFormInput fieldPath="code" label="Zugangscode" />

            <Button
              onPress={submitForm}
              isLoading={isSubmitting}
              mt={4}
              alignSelf="flex-start"
            >
              Zugang hinzufügen
            </Button>
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}

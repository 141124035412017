import { Heading } from "native-base";

type Props = {
  title: string;
};

export default function Headline({ title }: Props) {
  return (
    <Heading size="lg" mb={4}>
      {title}
    </Heading>
  );
}

import React, { useState } from "react";
import { createPortal } from "react-dom";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Stack, Box, Text } from "native-base";
import {
  DragOverlay,
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import TitlePhotos from "./capture/TitlePhotos.web";
import Areas from "./capture/Areas";
import OverviewMap from "./OverviewMap.web";
import { useAreas } from "../../hooks/useAreas.web";
import { useSortAreas } from "../../hooks/useSortAreas";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function ObjectCapture({ object, updateObject }: Props) {
  const sensors = useSensors(useSensor(PointerSensor));

  const [draggingAreaId, setDraggingAreaId] = useState<string | undefined>(
    undefined
  );

  const projectId = object.ref.parent.parent!.id;
  const objectId = object.id;

  const { areas, rootAreas, updateAreaSorting } = useAreas(projectId, objectId);

  const { areasToSort, handleDragStart, handleDragEnd } = useSortAreas(
    rootAreas as any,
    areas as any,
    updateAreaSorting,
    setDraggingAreaId
  );

  const draggingArea =
    draggingAreaId && areas.find((a) => a.id === draggingAreaId);

  return (
    <Stack space={4}>
      <OverviewMap object={object} updateObject={updateObject} />

      <TitlePhotos object={object} updateObject={updateObject} />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={areasToSort}
          strategy={verticalListSortingStrategy}
        >
          <Areas object={object as any} updateObject={updateObject as any} />
        </SortableContext>

        {createPortal(
          <DragOverlay>
            {draggingArea && (
              <Box w="full" h="12" p="4" bgColor="white" shadow="9" opacity="1">
                <Text fontSize="md" color="primary.400">
                  {draggingArea.get("title") as string}
                </Text>
              </Box>
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </Stack>
  );
}

import { useEffect, useState } from "react";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { firestore } from "../App.web";

export const useObjectWebAccessAccounts = (
  projectId: string,
  objectId: string
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const objectRef = doc(
      firestore,
      ["projects", projectId, "objects", objectId].join("/")
    );

    const q = query(
      collection(firestore, "webAccessAccounts"),
      where("objectRef", "==", objectRef)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setAccounts(snapshot.docs);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [setIsLoading, setAccounts, projectId, objectId]);

  return [accounts, isLoading] as const;
};

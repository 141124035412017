import { useCallback } from "react";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { firestore } from "../App.web";

export const useCreateProject = () => {
  return useCallback(
    async () =>
      addDoc(collection(firestore, "projects"), {
        state: "toCapture",
        createdAt: Timestamp.now(),
      }),
    []
  );
};

import { useCallback } from "react";
import { predefinedAreas } from "../helper/predefinedAreas";
import { useAreas } from "./useAreas";

type PredefinedAreaId =
  | "familyHome"
  | "familyHomeWithGardenHouse"
  | "apartmentHouse"
  | "street";

export type PredefinedAreaToCreate = {
  title: string;
  children?: PredefinedAreaToCreate[];
};

export type PredefinedArea = {
  id: PredefinedAreaId;
  label: string;
  areas: PredefinedAreaToCreate[];
};

export const usePredefinedAreas = (projectId: string, objectId: string) => {
  const { addArea } = useAreas(projectId, objectId);

  const createAreas = useCallback(
    async (id: PredefinedAreaId) => {
      const predefinedArea = predefinedAreas.find((x) => x.id === id);
      if (!predefinedArea) {
        return;
      }

      const recursivelyAddArea = async (
        areasToCreate: PredefinedAreaToCreate[],
        parentId: string | null
      ) => {
        let order = 0;

        for (const areaToCreate of areasToCreate) {
          order++;

          const { title, children } = areaToCreate;

          const createdArea = await addArea(title, parentId, order);

          if (createdArea && children) {
            await recursivelyAddArea(children, createdArea.id);
          }
        }
      };

      await recursivelyAddArea(predefinedArea.areas, null);
    },
    [addArea]
  );

  return [predefinedAreas, createAreas] as const;
};

import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";

export type ProjectStates = "toCapture" | "inProgress" | "done";

export type StateColor = "green" | "yellow" | "red";

export const stateColorMapping: { [Key in StateColor]: string } = {
  green: "#9ed5cf",
  yellow: "#ebc3a2",
  red: "#dfa5ad",
};

const stateTextMapping: { [Key in ProjectStates]: string } = {
  toCapture: "Zu Erfassen",
  inProgress: "In Bearbeitung",
  done: "Abgeschlossen",
};

const stateColorNameMapping: { [Key in ProjectStates]: StateColor } = {
  toCapture: "yellow",
  inProgress: "yellow",
  done: "green",
};

export const useProjectState = (
  project: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>
) => {
  const state: ProjectStates = project.get("state");

  const stateText = stateTextMapping[state];

  const stateColor = stateColorNameMapping[state];

  return { state, stateText, stateColor };
};

import {
  collection,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../App.web";
import { ProjectFilterType } from "../components/web/components/table/ProjectListFilter";

export const useProjectOverview = (projectFilter: ProjectFilterType) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const filterConditions: QueryConstraint[] = [];

    switch (projectFilter) {
      case "all":
        break;

      case "current":
        filterConditions.push(
          where("state", "in", ["toCapture", "inProgress"])
        );
        break;

      case "toCapture":
      case "inProgress":
      case "done":
        filterConditions.push(where("state", "==", projectFilter));
        break;
    }

    const q = query(
      collection(firestore, "projects"),
      orderBy("projectNumber", "desc"),
      ...filterConditions
    );

    const unsub = onSnapshot(q, (snapshot) => {
      setProjects(snapshot.docs);
      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [setIsLoading, setProjects, projectFilter]);

  return [projects, isLoading] as const;
};

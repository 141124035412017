import { Text, Pressable, AspectRatio, Center } from "native-base";

type Props = {
  onPress: () => void;
  children: React.ReactNode;
  noMargin?: boolean;
};

export default function PhotoButton({ onPress, children, noMargin }: Props) {
  return (
    <AspectRatio ratio={4 / 3} flex={1}>
      <Pressable
        bgColor="gray.50"
        borderRadius="2"
        onPress={onPress}
        _pressed={{ opacity: 0.5 }}
        w="full"
        h="full"
      >
        <Center
          h="full"
          w="full"
          py={noMargin ? undefined : 4}
          px={noMargin ? undefined : 8}
        >
          <Text textAlign="center">{children}</Text>
        </Center>
      </Pressable>
    </AspectRatio>
  );
}

import React, { useCallback, useState } from "react";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import lowerFirst from "lodash/lowerFirst";
import classNames from "classnames";
import { Crack, CrackType } from "../WebAccess";
import { CrackListItem } from "./area-to-image-table.helper";
import WebProtocolOverviewPhoto from "./WebProtocolOverviewPhoto";
import { thumbnailOrOriginalUrl } from "../../../helper/thumbnail-helper";

type Props = {
  crackListItem: CrackListItem;
  crackType?: CrackType;
};

const crackDescription = (crack: Crack | undefined) => {
  if (!crack) {
    return [];
  }

  const { title, description, position, width, length, lengthUnit } = crack;

  const linesOfText = [];

  const damageDescription = trim(
    `${description ?? ""} ${lowerFirst(position ?? "")}`
  );

  const mainLine = [];
  if (title && title.length > 0) {
    mainLine.push(title);
  }
  if (damageDescription && damageDescription.length > 0) {
    mainLine.push(damageDescription);
  }

  linesOfText.push(mainLine.join(", "));

  const secondLineContents = [];

  if (!isEmpty(width)) {
    secondLineContents.push(width);
  }

  if (!isEmpty(length)) {
    secondLineContents.push(`${length}${lengthUnit ?? ""}`);
  }

  if (secondLineContents.length > 0) {
    linesOfText.push(secondLineContents.join(", "));
  }

  return linesOfText;
};

export default function WebProtocolCrackListItem({
  crackListItem,
  crackType,
}: Props) {
  const [isExpanded, setExpanded] = useState(false);
  const { areaTitle, crack, note } = crackListItem;

  const detailPhotos = crack?.detailPhotos ?? [];

  const noVisibleChange = crack?.noVisibleChange;

  const toggleDetails = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      setExpanded((isCurrentlyExpanded) => !isCurrentlyExpanded);
    },
    [setExpanded]
  );

  return (
    <div className={classNames("crack-list-item", crackType)}>
      {crack && <WebProtocolOverviewPhoto crack={crack!} />}

      <div className="area">{areaTitle}</div>

      {crack && <div className="description">
        {crackDescription(crack)
          .map((text) => text)
          .join(" ")}
      </div>}

      {note && <div className="note">{note}</div>}

      {noVisibleChange && (
        <div className="note">Keine sichtbaren Änderungen</div>
      )}

      {detailPhotos?.length > 0 && (
        <div className="detail-photos-section">
          <a href="#" onClick={toggleDetails}>
            {`${detailPhotos.length} ${
              detailPhotos.length === 1 ? "Detailfoto" : "Detailfotos"
            }`}
          </a>

          {isExpanded && (
            <div className="detail-photos">
              {detailPhotos.map((photo) => (
                <div key={photo.url} className="detail-photo">
                  <a href={photo.url} target="_blank">
                    <img src={thumbnailOrOriginalUrl(photo)} />
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

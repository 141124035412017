import React from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import SelectFormInput from "../../form/SelectFormInput.web";
import TextFormInput from "../../form/TextFormInput.web";
import DateFormInput from "../../form/DateFormInput.web";
import TextAreaFormInput from "../../form/TextAreaFormInput.web";
import { useUpdateAppointment } from "../../../hooks/useUpdateAppointment.web";
import { Stack } from "native-base";
import DeleteAppointmentButton from "./DeleteAppointmentButton";
import TwoCols from "../../web/shared/TwoCols";
import { statusTypeOptions } from "../../../helper/status-types";
import CreateAppointmentLetterButton from "./CreateAppointmentLetterButton";
import SalutationField from "../../web/components/SalutationField";
import { durationOptions } from "../../../helper/duration";

type Props = {
  appointment: DocumentSnapshot<DocumentData>;
  object: DocumentSnapshot<DocumentData>;
  project: DocumentSnapshot<DocumentData>;
};

export default function ObjectAppointment({
  appointment,
  object,
  project,
}: Props) {
  const { id } = appointment;

  const updateAppointment = useUpdateAppointment(id);

  return (
    <Stack my={4}>
      <TwoCols
        leftContent={
          <>
            <DateFormInput
              label="Datum"
              dateTime
              fieldPath="appointmentDate"
              doc={appointment}
              updateDoc={updateAppointment}
            />

            <SelectFormInput
              label="Dauer"
              placeholder="Dauer auswählen"
              options={durationOptions}
              fieldPath="duration"
              doc={appointment}
              updateDoc={updateAppointment}
            />

            <SelectFormInput
              label="Stand"
              placeholder="Status auswählen"
              options={statusTypeOptions}
              fieldPath="state"
              doc={appointment}
              updateDoc={updateAppointment}
            />

            <TextFormInput
              label="Wohnung / Grundstück"
              fieldPath="title"
              doc={appointment}
              updateDoc={updateAppointment}
            />

            <TextAreaFormInput
              label="Bemerkungen"
              fieldPath="notes"
              doc={appointment}
              updateDoc={updateAppointment}
            />
          </>
        }
        rightContent={
          <>
            <TextAreaFormInput
              label="Adresse"
              fieldPath="address"
              doc={appointment}
              updateDoc={updateAppointment}
            />

            <SalutationField
              object={appointment as any}
              updateObject={updateAppointment as any}
            />

            <CreateAppointmentLetterButton
              appointment={appointment as any}
              object={object as any}
              project={project as any}
            />

            <DeleteAppointmentButton appointment={appointment as any} />
          </>
        }
      />
    </Stack>
  );
}

import { useEffect, useState } from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { getInfoAsync } from "expo-file-system";
import { isEmpty } from "lodash";

export const usePhotoThumbnail = (
  photo: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>,
  showOriginal?: boolean
) => {
  const { localUrl, url, thumbnailLocalUrl, thumbnailUrl } =
    photo?.data() || {};

  const [localUrlState, setLocalUrlState] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      setLocalUrlState(undefined);

      // locally we can prefer higher resolution images if available
      const localUrlsToCheck = [localUrl];
      if (!showOriginal) {
        localUrlsToCheck.push(thumbnailLocalUrl);
      }

      for await (const url of localUrlsToCheck) {
        if (!url) {
          continue;
        }

        try {
          const fileInfo = await getInfoAsync(url);

          if (fileInfo.exists) {
            setLocalUrlState(url);
            break;
          }
        } catch (e) {}
      }
    })();
  }, [localUrl, thumbnailLocalUrl, showOriginal]);

  const isUploading = isEmpty(url);

  let imageURI: string | undefined = undefined;

  // fallback to local image in case the images have not been uploaded yet
  if (showOriginal) {
    imageURI = url ?? localUrlState;
  } else {
    // remotely we prefer thumbnails, if available
    imageURI = thumbnailUrl ?? url ?? localUrlState;
  }

  return [imageURI, isUploading] as const;
};

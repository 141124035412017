import { useFirebaseObject } from "./useFirebaseObject";

export const useArea = (
  projectId: string,
  objectId: string,
  areaId: string
) => {
  const [area, updateArea, isLoading] = useFirebaseObject(
    ["projects", projectId, "objects", objectId, "areas", areaId].join("/")
  );

  return { area, updateArea, isLoading };
};

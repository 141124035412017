import {
  doc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { firestore } from "../App.web";

export const useFirebaseObject = (docPath: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [object, setObject] = useState<
    DocumentSnapshot<DocumentData> | undefined
  >();

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, docPath),
      (doc) => {
        setObject(doc);
        setIsLoading(false);
      },
      (error) => console.error(error)
    );

    return () => {
      unsub();
    };
  }, [docPath, setIsLoading]);

  const updateObject = useCallback(
    (data: any) => updateDoc(doc(firestore, docPath), data),
    [docPath]
  );

  return [object, updateObject, isLoading];
};

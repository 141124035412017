import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import ObjectWebAccessItem from "./ObjectWebAccessItem";

type Props = {
  webAccessId: string;
  webAccessAccounts: DocumentSnapshot<DocumentData>[];
};

export default function ObjectWebAccessItems({
  webAccessId,
  webAccessAccounts,
}: Props) {
  if (!webAccessAccounts || webAccessAccounts.length <= 0) {
    return null;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>E-Mail</th>
          <th>Zugangscode</th>
          <th>Aktionen</th>
        </tr>
      </thead>

      <tbody>
        {webAccessAccounts.map((webAccess) => (
          <ObjectWebAccessItem
            key={webAccess.id}
            webAccess={webAccess}
            webAccessId={webAccessId}
          />
        ))}
      </tbody>
    </table>
  );
}

import { useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../App.web";

export const useUpdateAppointment = (appointmentId: string) => {
  return useCallback(
    (data: any) =>
      updateDoc(
        doc(firestore, ["appointments", appointmentId].join("/")),
        data
      ),
    [appointmentId]
  );
};

import React, { useCallback, useState } from "react";
import { Protocol } from "../WebAccess";
import RequestArchiveForm from "./RequestArchiveForm";

type Props = {
  protocol: Protocol;
};

export default function ArchiveDownload({ protocol }: Props) {
  const [hasArchiveBeenRequested, setArchiveBeenRequested] = useState(false);

  const afterArchiveRequested = useCallback(() => {
    setArchiveBeenRequested(true);
  }, [setArchiveBeenRequested]);

  const { downloadRequestedAt, downloadUrl } = protocol;

  if (downloadUrl) {
    return (
      <a href={downloadUrl} target="_blank">
        ZIP-Archiv aller Fotos herunterladen
      </a>
    );
  }

  if (downloadRequestedAt || hasArchiveBeenRequested) {
    return (
      <p>
        Das ZIP-Archiv wird gerade erstellt. Sie erhalten eine E-Mail mit einem
        Download-Link, sobald die Datei zum Download bereit steht.
      </p>
    );
  }

  return (
    <RequestArchiveForm
      protocol={protocol}
      afterArchiveRequested={afterArchiveRequested}
    />
  );
}

import { useCallback, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { getImageSize } from "react-image-size";
import { firestore, storage } from "../App.web";
import { useDropzone } from "react-dropzone";
import { useCreateThumbnail } from "./useCreateThumbnail";
import { colors } from "../colors";

type PhotoType =
  | "detailPhoto"
  | "overviewPhoto"
  | "objectOverviewPhoto"
  | "overviewMap";

const photoTypeToCollectionMapping = {
  detailPhoto: "photos",
  overviewPhoto: "photos",
  objectOverviewPhoto: "overviewPhotos",
  overviewMap: "overviewMaps",
};

export const dragActiveStyle = (isDragAccept: boolean) =>
  isDragAccept
    ? {
        outline: `2px solid ${colors.primary}`,
        outlineOffset: "2px",
      }
    : undefined;

export const useUploadPhotoToObject = (
  photoType: PhotoType,
  projectId: string,
  objectId: string,
  crackId?: string,
  maxFiles?: number
) => {
  const [isUploading, setUploading] = useState(false);

  const createThumbnail = useCreateThumbnail(true);

  const uploadFile = useCallback(
    async (file: File) => {
      const { name } = file;
      const objectURL = URL.createObjectURL(file);
      const { width, height } = await getImageSize(objectURL);

      const thumbnail = await createThumbnail(objectURL);

      const data: any = {
        photoType,
        capturedAt: Timestamp.now(),
        width,
        height,
        localUrl: name,
      };

      const photoCollection = photoTypeToCollectionMapping[photoType];

      if (crackId) {
        data.crackRef = doc(
          firestore,
          ["projects", projectId, "objects", objectId, "cracks", crackId].join(
            "/"
          )
        );
      }

      const photoRef = await addDoc(
        collection(
          firestore,
          ["projects", projectId, "objects", objectId, photoCollection].join(
            "/"
          )
        ),
        data
      );

      const extension = name.split(".").pop();

      const storageRef = ref(
        storage,
        `photos/${projectId}/${objectId}/${photoRef.id}.${extension}`
      );

      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      let thumbnailUrl: string | undefined = undefined;

      if (thumbnail.success && thumbnail.base64) {
        const thumbnailStorageRef = ref(
          storage,
          `photos/${projectId}/${objectId}/${photoRef.id}-thumbnail.${extension}`
        );

        await uploadString(thumbnailStorageRef, thumbnail.base64, "base64", {
          contentType: "image/jpeg",
        });

        thumbnailUrl = await getDownloadURL(thumbnailStorageRef);
      }

      updateDoc(photoRef, {
        url,
        thumbnailUrl,
        localUrl: null,
      });
    },
    [photoType, projectId, objectId, crackId]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);

      for (const file of acceptedFiles) {
        try {
          await uploadFile(file);
        } catch (e) {
          console.error(e);
        }
      }

      setUploading(false);
    },
    [uploadFile, setUploading]
  );

  const dropzone = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles,
  });

  const { open: uploadPhoto } = dropzone;

  return [uploadPhoto, isUploading, dropzone] as const;
};

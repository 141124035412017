import React, { useState } from "react";
import { View } from "react-native";
import { ImageSize } from "../object/capture/MarkCracks";
import PhotoThumbnail from "./PhotoThumbnail";
import DisplayCrackMark from "../object/capture/DisplayCrackMark";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCrackMarks } from "../../hooks/useCrackMarks";
import { DEFAULT_CRACK_MARK_SIZE } from "../../hooks/useCrack";
import CrackMark from "../object/capture/CrackMark.web";

type Props = {
  photo: DocumentSnapshot<DocumentData>;
  crack: DocumentSnapshot<DocumentData>;
  showOriginal?: boolean;
  isEditModeActive?: boolean;
};

export default function PhotoThumbnailWithCrackMarks({
  photo,
  crack,
  showOriginal,
  isEditModeActive,
}: Props) {
  const projectId = crack.ref.parent.parent!.parent!.parent!.id;
  const objectId = crack.ref.parent.parent!.id;

  const [imageSize, setImageSize] = useState<ImageSize | undefined>(undefined);

  const { crackMarks, addCrackMark } = useCrackMarks(
    projectId,
    objectId,
    crack.id
  );

  const addCrackMarking = (x: number, y: number) => {
    if (!imageSize) {
      return;
    }

    const { width, height } = imageSize;
    const xInPercent = (x / width) * 100;
    const yInPercent = (y / height) * 100;

    addCrackMark(xInPercent, yInPercent, DEFAULT_CRACK_MARK_SIZE);
  };

  const tapGesture = Gesture.Tap()
    .runOnJS(true)
    .onStart((event) => {
      const { x, y } = event;

      addCrackMarking(x, y);
    });

  return (
    <PhotoThumbnail
      photo={photo as any}
      onLayout={(e) => {
        const { width, height } = e.nativeEvent.layout;
        setImageSize({ width, height });
      }}
      showOriginal={showOriginal}
    >
      {isEditModeActive && (
        <GestureDetector gesture={tapGesture}>
          <View
            style={
              {
                cursor: "crosshair",
                width: "100%",
                height: "100%",
                position: "absolute",
              } as any
            }
          />
        </GestureDetector>
      )}

      {crackMarks.map((cm) =>
        isEditModeActive ? (
          <CrackMark
            key={cm.id}
            crackMarking={cm as any}
            imageSize={imageSize}
          />
        ) : (
          <DisplayCrackMark
            key={cm.id}
            crackMarking={cm}
            imageSize={imageSize}
          />
        )
      )}
    </PhotoThumbnail>
  );
}

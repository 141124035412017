import React from "react";
import { Divider, HStack, Spinner, Stack, Text, Box } from "native-base";
import { useParams } from "react-router-dom";
import TextFormInput from "../form/TextFormInput.web";
import TextAreaFormInput from "../form/TextAreaFormInput.web";
import Headline from "../typo/Headline";
import { objectTypeOptions, useObject } from "../../hooks/useObject";
import DateFormInput from "../form/DateFormInput.web";
import SelectFormInput from "../form/SelectFormInput.web";
import { useObjectAppointments } from "../../hooks/useObjectAppointments";
import StateIndicator from "../shared/StateIndicator";
import ObjectAppointment from "../object/appointment/ObjectAppointment.web";
import CreateAppointmentButton from "../object/appointment/CreateAppointmentButton.web";
import ObjectStateAsFormField from "../object/ObjectStateAsFormField";
import ObjectProtocol from "../object/ObjectProtocol.web";
import DeleteObjectButton from "../object/DeleteObjectButton.web";
import Spacer from "./components/Spacer";
import ObjectCapture from "../object/ObjectCapture.web";
import TwoCols from "./shared/TwoCols";
import ObjectWebAccess from "../object/ObjectWebAccess";
import ProtocolAuthorField from "./components/ProtocolAuthorField";
import {
  appointmentColorMapping,
  AppointmentState,
  appointmentStateMapping,
} from "../../helper/status-types";
import SalutationField from "./components/SalutationField";
import { useProject } from "../../hooks/useProject";
import FollowUpInspection from "../object/FollowUpInspection.web";

export default function ObjectDetails() {
  const { projectId, objectId } = useParams();

  const { project } = useProject(projectId!);

  const { object, updateObject, isLoading } = useObject(projectId!, objectId!);

  const [, groupedAppointments] = useObjectAppointments(projectId!, objectId!);

  return (
    <Stack px={5} py={6}>
      <Headline title="Objekt" />

      {isLoading ? (
        <Spinner />
      ) : (
        <Stack>
          <TwoCols
            leftContent={
              <>
                <ObjectStateAsFormField object={object as any} />

                <TextFormInput
                  label="Objekt"
                  fieldPath="title"
                  doc={object as any}
                  updateDoc={updateObject as any}
                />

                <SelectFormInput
                  label="Objekttyp"
                  placeholder="Typ wählen"
                  options={objectTypeOptions}
                  fieldPath="objectType"
                  doc={object as any}
                  updateDoc={updateObject as any}
                />

                <ProtocolAuthorField
                  object={object as any}
                  updateObject={updateObject as any}
                />
              </>
            }
            rightContent={
              <>
                <DateFormInput
                  label="Aufnahmedatum"
                  fieldPath="captureDate"
                  doc={object as any}
                  updateDoc={updateObject as any}
                />

                <TextFormInput
                  label="Standort / Adresse"
                  fieldPath="address"
                  doc={object as any}
                  updateDoc={updateObject as any}
                />

                <TextFormInput
                  label="Grundstücksnummer"
                  fieldPath="propertyNumber"
                  doc={object as any}
                  updateDoc={updateObject as any}
                />

                <TextAreaFormInput
                  label="Eigentümer"
                  fieldPath="owner"
                  doc={object as any}
                  updateDoc={updateObject as any}
                />

                <SalutationField
                  object={object as any}
                  updateObject={updateObject as any}
                />
              </>
            }
          />

          <Spacer />

          <Headline title="Termine" />

          {groupedAppointments.map(({ state, appointments }, idx) => (
            <Box key={state} mb={6}>
              <HStack space={2} mt={idx !== 0 ? 6 : undefined}>
                <Box pt={1}>
                  <StateIndicator
                    stateColor={
                      appointmentColorMapping[state as AppointmentState]
                    }
                  />
                </Box>
                <Text fontSize="lg" fontWeight="bold">
                  {appointmentStateMapping[state as AppointmentState]}
                </Text>
              </HStack>

              <Divider my={4} thickness={2} />

              <Stack space={4} pb={2}>
                {appointments.map((appointment) => (
                  <ObjectAppointment
                    key={appointment.id}
                    appointment={appointment as any}
                    object={object as any}
                    project={project as any}
                  />
                ))}
              </Stack>
            </Box>
          ))}

          <CreateAppointmentButton
            projectId={projectId!}
            objectId={objectId!}
          />

          <Spacer />

          <Headline title="Erfassung" />

          <ObjectCapture
            object={object as any}
            updateObject={updateObject as any}
          />

          <Spacer />

          <Headline title="Protokoll" />

          <ObjectProtocol
            object={object as any}
            updateObject={updateObject as any}
          />

          <Spacer />

          <Headline title="Nachkontrolle" />

          <FollowUpInspection object={object as any} />

          <Spacer />

          <Headline title="Webprotokoll" />

          <ObjectWebAccess
            object={object as any}
            updateObject={updateObject as any}
          />

          <DeleteObjectButton object={object as any} />
        </Stack>
      )}
    </Stack>
  );
}

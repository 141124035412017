import { useCallback, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../App.web";
import { useDropzone } from "react-dropzone";
import { colors } from "../colors";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";

export const dragActiveStyle = (isDragAccept: boolean) =>
  isDragAccept
    ? {
        outline: `2px solid ${colors.primary}`,
        outlineOffset: "2px",
      }
    : undefined;

export const useUploadSignature = (
  doc: DocumentSnapshot<DocumentData>,
  updateDoc: (data: any) => Promise<void>,
) => {
  const [isUploading, setUploading] = useState(false);

  const uploadFile = useCallback(
    async (file: File) => {
      const { name } = file;

      const extension = name.split(".").pop();

      const uploadId = self.crypto.randomUUID();

      const storageRef = ref(
        storage,
        `signatures/${doc.id}/${uploadId}.${extension}`,
      );

      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      updateDoc({ signatureImageUrl: url });
    },
    [doc.id, updateDoc],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);

      for (const file of acceptedFiles) {
        try {
          await uploadFile(file);
        } catch (e) {
          console.error(e);
        }
      }

      setUploading(false);
    },
    [uploadFile, setUploading],
  );

  const dropzone = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: 1,
  });

  const { open: uploadSignature } = dropzone;

  return [uploadSignature, isUploading, dropzone] as const;
};

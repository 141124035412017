import { useMemo } from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { useObjects } from "./useObjects";
import { useObjectsAppointments } from "./useObjectsAppointments";
import { ProjectStates, StateColor } from "./useProjectState";

const colorForCount: (
  count: number,
  totalCount: number,
) => StateColor | undefined = (count, totalCount) => {
  if (totalCount <= 0) {
    return undefined;
  } else if (count === totalCount) {
    return "green";
  } else if (count === 0) {
    return "red";
  } else {
    return "yellow";
  }
};

const countObjectState = (
  fieldPath: string,
  objects: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[],
) => objects.filter((o) => !!o.get(fieldPath)).length;

export const useCalculateProjectDetailState = (projectId: string) => {
  console.log("In useCalculateProjectDetailState", projectId);

  const [objects, isLoadingObjects] = useObjects(projectId);

  // memoize because otherwise useObjectsAppointments will be called over and over again (as new array with same content is created)
  const objectIds = useMemo(() => objects.map((o) => o.id), [objects]);

  const [appointments, isLoadingAppointments] = useObjectsAppointments(
    projectId,
    objectIds,
  );

  const objectsCount = objects.length;

  const confirmedAppointmentsCount = appointments.filter(
    (a) => a.get("state") === "confirmed",
  ).length;
  const totalAppointmentsCount = appointments.length;

  const protocolsCreatedCount = countObjectState("protocolCreatedAt", objects);
  const sentToOwnerCount = countObjectState("sentToOwnerAt", objects);
  const returnedFromOwnerCount = countObjectState(
    "returnedFromOwnerAt",
    objects,
  );
  const sentToClientCount = countObjectState("sentToClientAt", objects);
  const returnedFromClientCount = countObjectState(
    "returnedFromClientAt",
    objects,
  );
  const sentFinalToOwnerCount = countObjectState("sentFinalToOwnerAt", objects);
  const manuallyClosedCount = countObjectState("manualClosureAt", objects);
  const completedObjectsCount = sentFinalToOwnerCount + manuallyClosedCount;

  const confirmedAppointmentsColor = colorForCount(
    confirmedAppointmentsCount,
    totalAppointmentsCount,
  );

  const protocolsCreatedColor = colorForCount(
    protocolsCreatedCount,
    objectsCount,
  );

  const sentToOwnerColor = colorForCount(sentToOwnerCount, objectsCount);

  const returnedFromOwnerColor = colorForCount(
    returnedFromOwnerCount,
    objectsCount,
  );

  const sentToClientColor = colorForCount(sentToClientCount, objectsCount);

  const returnedFromClientColor = colorForCount(
    returnedFromClientCount,
    objectsCount,
  );

  const sentFinalToOwnerColor = colorForCount(
    sentFinalToOwnerCount,
    objectsCount,
  );

  // project state
  let projectState: ProjectStates | undefined = undefined;

  // using >= instead of === as some projects can be counted twice (when the project is both manually closed and really completed)
  // if (objectsCount > 0 && completedObjectsCount >= objectsCount) {
  if (objectsCount > 0 && completedObjectsCount >= objectsCount) {
    projectState = "done";
  } else if (objectsCount > 0 && protocolsCreatedCount >= objectsCount) {
    projectState = "inProgress";
  } else {
    projectState = "toCapture";
  }

  const isLoaded = !isLoadingObjects && !isLoadingAppointments;

  if (!objects || !isLoaded) {
    return undefined;
  }

  return {
    projectState,
    objectsCount,
    totalAppointmentsCount,
    confirmedAppointmentsCount,
    confirmedAppointmentsColor,
    protocolsCreatedCount,
    protocolsCreatedColor,
    sentToOwnerCount,
    sentToOwnerColor,
    returnedFromOwnerCount,
    returnedFromOwnerColor,
    sentToClientCount,
    sentToClientColor,
    returnedFromClientCount,
    returnedFromClientColor,
    sentFinalToOwnerCount,
    sentFinalToOwnerColor,
  };
};

import React, { useCallback } from "react";
import { Button } from "native-base";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCreateLetter } from "../../../hooks/useCreateLetter";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function CreateRetourLetterButton({ object }: Props) {
  const [createLetter, isLoading] = useCreateLetter("retour");

  const protocolAuthor = object?.get("protocolAuthor") ?? "";

  const createRetourLetter = useCallback(() => {
    const payload = {
      protocolAuthor,
    };

    createLetter(payload);
  }, [createLetter, protocolAuthor]);

  return (
    <Button onPress={createRetourLetter} variant="solid" isLoading={isLoading}>
      Retourbrief
    </Button>
  );
}

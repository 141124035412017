import { moveAsync } from "expo-file-system";
import * as ImageManipulator from "expo-image-manipulator";
import { SaveFormat } from "expo-image-manipulator";
import { useCallback } from "react";

const TARGET_WIDTH_IN_PX = 1024;

export const useCreateThumbnail = (returnBase64?: boolean) => {
  return useCallback(async (localUri: string, targetUri?: string) => {
    let base64: string | undefined = undefined;

    try {
      const result = await ImageManipulator.manipulateAsync(
        localUri,
        [{ resize: { width: TARGET_WIDTH_IN_PX } }],
        {
          base64: returnBase64,
          compress: 0.8,
          format: SaveFormat.JPEG,
        }
      );

      base64 = result.base64;

      if (targetUri) {
        await moveAsync({ from: result.uri, to: targetUri });
      }
    } catch (e: any) {
      console.error(e);

      return { success: false };
    }

    return { success: true, base64 };
  }, []);
};

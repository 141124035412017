import { addDoc, collection, doc, Timestamp } from "firebase/firestore";
import { useCallback } from "react";
import { firestore } from "../App.web";

export const useCreateAppointment = (projectId: string, objectId: string) => {
  return useCallback(async () => {
    const projectRef = doc(firestore, ["projects", projectId].join("/"));

    const objectRef = doc(
      firestore,
      ["projects", projectId, "objects", objectId].join("/")
    );

    addDoc(collection(firestore, "appointments"), {
      state: "open",
      projectRef,
      objectRef,
      createdAt: Timestamp.now(),
    });
  }, [projectId, objectId]);
};

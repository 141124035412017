import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { isEmpty } from "lodash";
import { Stack } from "native-base";
import { useAreas } from "../../../hooks/useAreas";
import { useCracks } from "../../../hooks/useCracks";
import { ObjectType } from "../../../hooks/useObjectType";
import Subsection from "../../typo/Subsection";
import DisplayAreas from "./DisplayAreas";
import PrefillAreas from "./PrefillAreas";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function Areas({ object, updateObject }: Props) {
  const projectId = object.ref.parent.parent!.id;
  const objectId = object.id;

  const {
    areas,
    addArea,
    rootAreas,
    levelForAreaId,
    isLoading: isLoadingAreas,
  } = useAreas(projectId, objectId);

  const [, cracksByAreaId, , isLoadingCracks] = useCracks(projectId, objectId);

  if (isLoadingCracks || isLoadingAreas || !object) {
    return null;
  }

  const objectType = object.get<ObjectType | null>("objectType");

  return (
    <Stack space={8}>
      <Subsection title="Bereiche" />

      <DisplayAreas
        objectType={objectType}
        projectId={projectId}
        objectId={objectId}
        areas={rootAreas}
        allAreas={areas}
        levelForAreaId={levelForAreaId}
        parentId={null}
        addArea={addArea}
        cracksByAreaId={cracksByAreaId}
        isLoadingCracks={isLoadingCracks}
      />

      {isEmpty(areas) && (
        <PrefillAreas object={object} updateObject={updateObject} />
      )}
    </Stack>
  );
}

import React, { useState } from "react";
import { Spinner, Icon, Stack } from "native-base";
import { Feather } from "@expo/vector-icons";
import { useProjectOverview } from "../../../../hooks/useProjectOverview.web";
import ProjectOverviewItem from "./ProjectOverviewItem";
import TableHeadline from "./TableHeadline";
import TableIconHeadline from "./TableIconHeadline";
import ProjectListFilter, { ProjectFilterType } from "./ProjectListFilter";

export default function ProjectsList() {
  const [projectFilter, setProjectFilter] =
    useState<ProjectFilterType>("current");

  const [projects, isLoading] = useProjectOverview(projectFilter);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Stack space={4}>
      <ProjectListFilter
        projectFilter={projectFilter}
        setProjectFilter={setProjectFilter}
      />

      <table>
        <thead>
          <tr>
            <TableHeadline>Projekt-Nr.</TableHeadline>
            <TableHeadline>Projektname</TableHeadline>
            <TableHeadline>Status</TableHeadline>
            <TableIconHeadline tooltip="Termine fix">
              <Icon as={Feather} name="calendar" />
            </TableIconHeadline>
            <TableIconHeadline tooltip="Protokolle erstellt">
              <Icon as={Feather} name="file-text" />
            </TableIconHeadline>
            <TableIconHeadline tooltip="An Eigentümer verschickt">
              <Icon as={Feather} name="send" />
            </TableIconHeadline>
            <TableIconHeadline tooltip="Von Eigentümer zurück">
              <span style={{ transform: "rotate(180deg)" }}>
                <Icon as={Feather} name="send" />
              </span>
            </TableIconHeadline>
            <TableIconHeadline tooltip="An Auftraggeber verschickt">
              <Icon as={Feather} name="send" />
            </TableIconHeadline>
            <TableIconHeadline tooltip="Von Auftraggeber zurück">
              <span style={{ transform: "rotate(180deg)" }}>
                <Icon as={Feather} name="send" />
              </span>
            </TableIconHeadline>
            <TableIconHeadline tooltip="An Eigentümer gesendet">
              <Icon as={Feather} name="flag" />
            </TableIconHeadline>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <ProjectOverviewItem key={project.id} project={project} />
          ))}
        </tbody>
      </table>
    </Stack>
  );
}

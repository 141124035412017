import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { CurrentUserContext } from "../App";

type Props = {
  children: JSX.Element;
};

export default function RequireAuth({ children }: Props) {
  const currentUser = useContext(CurrentUserContext);
  const location = useLocation();

  if (!currentUser) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
}

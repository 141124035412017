import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Stack } from "native-base";
import { useObjectPhotoCount } from "../../hooks/useObjectPhotoCount";
import CreateProtocolButton from "./protocol/CreateProtocolButton";
import ObjectProtocolTimestamps from "./protocol/ObjectProtocolTimestamps";
import ObjectUploadState from "./protocol/ObjectUploadState";

type Props = {
  object: DocumentSnapshot<DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function ObjectProtocol({ object, updateObject }: Props) {
  const { photosUploaded, photosTotal, isLoading } = useObjectPhotoCount(
    object.ref.parent.parent!.id,
    object.id
  );

  if (isLoading || !object) {
    return null;
  }

  const { protocolCreatedAt, protocolDOCXUrl } = object.data() || {};

  if (protocolCreatedAt && protocolDOCXUrl) {
    return (
      <ObjectProtocolTimestamps
        object={object as any}
        updateObject={updateObject as any}
      />
    );
  }

  return (
    <Stack space={4}>
      <ObjectUploadState
        photosUploaded={photosUploaded}
        photosTotal={photosTotal}
      />

      <CreateProtocolButton
        object={object as any}
        isPhotoUploadIncomplete={
          photosTotal !== photosUploaded || photosTotal === 0
        }
      />
    </Stack>
  );
}

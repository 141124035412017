import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { Stack } from "native-base";
import { AreaLevelLookup } from "../../../hooks/useAreas";
import AddAreaButton from "./AddAreaButton";
import DisplayArea from "./DisplayArea";
import { ObjectType } from "../../../hooks/useObjectType";

type Props = {
  objectType: ObjectType | null;
  projectId: string;
  objectId: string;
  areas: FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[];
  allAreas: FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[];
  levelForAreaId: AreaLevelLookup;
  parentId: string | null;
  addArea: (areaName: string, parentId: string | null) => void;
  cracksByAreaId: Map<
    string,
    FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>[]
  >;
  isLoadingCracks: boolean;
  rootAreaType?: string;
};

export default function DisplayAreas({
  objectType,
  projectId,
  objectId,
  areas,
  allAreas,
  levelForAreaId,
  parentId,
  addArea,
  cracksByAreaId,
  isLoadingCracks,
  rootAreaType,
}: Props) {
  const couldAddSubareas = !(objectType === "street" && allAreas.length > 0);

  //  TODO: Allow sorting!

  return (
    <Stack>
      {areas.map((area) => (
        <DisplayArea
          key={area.id}
          objectType={objectType}
          area={area}
          projectId={projectId}
          objectId={objectId}
          allAreas={allAreas}
          levelForAreaId={levelForAreaId}
          addArea={addArea}
          cracksByAreaId={cracksByAreaId}
          isLoadingCracks={isLoadingCracks}
          rootAreaType={rootAreaType}
        />
      ))}

      {parentId == null && couldAddSubareas && (
        <AddAreaButton
          onAddAreaWithName={(areaName) =>
            areaName && addArea(areaName, parentId)
          }
          level={1}
          objectType={objectType}
        />
      )}
    </Stack>
  );
}

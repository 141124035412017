import { doc, updateDoc } from "firebase/firestore";
import { useToast } from "native-base";
import { useCallback, useState } from "react";
import Constants from "expo-constants";
import { firestore } from "../App.web";
import { useCreateInitialWebAccess } from "./useCreateInitialWebAccess";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

export const useCreateProtocol = (projectId: string, objectId: string) => {
  const toast = useToast();
  const createInitialWebAccess = useCreateInitialWebAccess(projectId, objectId);

  const [isLoading, setIsLoading] = useState(false);

  const createProtocol = useCallback(
    async (includeDetailPhotos: boolean) => {
      setIsLoading(true);

      try {
        const objectRef = doc(
          firestore,
          ["projects", projectId, "objects", objectId].join("/")
        );

        await updateDoc(objectRef, {
          protocolDOCXUrl: null,
          protocolPDFUrl: null,
        });

        await createInitialWebAccess();

        await fetch(`${BACKEND_API_BASE_URL}/produce-message`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload: JSON.stringify({
              job: "createDocument",
              projectId,
              objectId,
              includeDetailPhotos,
            }),
          }),
        });

        await updateDoc(objectRef, {
          protocolCreatedAt: new Date(),
        });
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title: "Protokoll konnte nicht generiert werden",
          description: String(e),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [projectId, objectId, setIsLoading, toast]
  );

  return [createProtocol, isLoading] as const;
};

import useMeasure from "react-use/lib/useMeasure";
import { thumbnailOrOriginalUrl } from "../../../helper/thumbnail-helper";
import { Crack } from "../WebAccess";

type Props = {
  crack: Crack;
};

export default function WebProtocolOverviewPhoto({ crack }: Props) {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  if (!crack.photo) {
    return null;
  }

  return (
    <div ref={ref} className="photo">
      <a href={crack.photo.url} target="_blank">
        <img src={thumbnailOrOriginalUrl(crack.photo)} />
      </a>

      {crack.crackMarks.map(({ x, y, size }) => {
        const sizeInPx = ((width ?? 0) * size) / 100;

        return (
          <div
            key={[x, y, size].join("-")}
            className="crack-mark"
            style={{
              width: sizeInPx,
              height: sizeInPx,
              top: `${y}%`,
              left: `${x}%`,
              transform: `translate(-${sizeInPx / 2}px, -${sizeInPx / 2}px)`,
            }}
          />
        );
      })}
    </div>
  );
}

import React from "react";
import { Text } from "native-base";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
  children: React.ReactNode;
};

export default function NavigationItem({ to, children }: Props) {
  return (
    <Text fontSize="lg">
      <NavLink to={to} style={({ isActive }) => (isActive ? {} : {})}>
        {children}
      </NavLink>
    </Text>
  );
}

import { useEffect, useMemo, useState } from "react";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { orderBy } from "lodash";
import { groupAppointments } from "../helper/appointment-grouping-helper";
import { firestore } from "../App.web";
import { AppointmentState } from "../helper/status-types";

export type AppointmentsGroupedByState = {
  state: AppointmentState;
  appointments: DocumentSnapshot<DocumentData>[];
};

export const useObjectAppointments = (projectId: string, objectId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);

  useEffect(() => {
    setIsLoading(true);

    const objectRef = doc(
      firestore,
      ["projects", projectId, "objects", objectId].join("/")
    );

    const q = query(
      collection(firestore, "appointments"),
      where("objectRef", "==", objectRef)
    );

    const unsub = onSnapshot(q, (snapshot) => {
      const sorted = orderBy(
        snapshot.docs,
        [(d) => d.get("appointmentDate")],
        ["asc"]
      );
      setAppointments(sorted);

      setIsLoading(false);
    });

    return () => {
      unsub();
    };
  }, [setIsLoading, setAppointments, objectId]);

  const groupedAppointments = useMemo(
    () => groupAppointments(appointments),
    [appointments]
  );

  return [appointments, groupedAppointments, isLoading] as const;
};

import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { ObjectType } from "../../../hooks/useObjectType";
import { Area, Crack } from "../WebAccess";
import WebProtocolCrackListItem from "./WebProtocolCrackListItem";
import WebProtocolStreetCrackListItem from "./WebProtocolStreetCrackListItem";
import {
  isFollowUpForCrackListItem,
  isOriginalForCrackListItem,
} from "../../../helper/crack-helper";

export type CrackListItem = {
  areaTitle: string;
  crack?: Crack;
  note?: string;
  isNote?: boolean;
};

// called once for level 1 areas
export const areaToImageTableHelper = (
  area: Area,
  rootIndex: number,
  objectType: ObjectType,
  isFollowUpInspection?: boolean
) => {
  type AreaTable = {
    title: string;
    indexes: number[];
    level: number;
    crackListItems: CrackListItem[];
    children: AreaTable[];
  };

  const walkChildrenAreas = (
    areas: Area[],
    ancestors: Area[],
    parent: AreaTable
  ) => {
    areas.forEach((currentArea, index) => {
      const { title, children, notes, cracks } = currentArea;

      const isLeaf = children.length === 0;

      if (isLeaf) {
        const notesAsCrackListItem: CrackListItem[] = [];

        if (!isEmpty(notes)) {
          notesAsCrackListItem.push({
            areaTitle: title,
            note: notes,
            isNote: true
          } as CrackListItem);
        }

        parent.crackListItems = [
          ...parent.crackListItems,
          ...notesAsCrackListItem,
          ...cracks.map((crack) => {
            return {
              areaTitle: title,
              crack,
            } as CrackListItem;
          }),
        ];
      } else {
        const subAreaTable: AreaTable = {
          title: title ?? "",
          indexes: [...parent.indexes, index + 1],
          level: parent.level + 1,
          crackListItems: cracks.map((crack) => {
            return {
              areaTitle: title,
              crack,
            } as CrackListItem;
          }),
          children: [],
        };

        parent.children = [...parent.children, subAreaTable];

        walkChildrenAreas(children, [...ancestors, currentArea], subAreaTable);
      }
    });
  };

  const areaTables: AreaTable = {
    title: area?.title ?? "",
    indexes: [3, rootIndex],
    level: 1,
    crackListItems: [],
    children: [],
  };

  if (objectType === "street") {
    walkChildrenAreas([area], [], areaTables);
  } else {
    walkChildrenAreas(area.children, [area], areaTables);
  }

  const imageTables: React.ReactNode[] = [];

  let lastHeadline: string | null = null;

  const createImageTables = (
    areaTables: AreaTable[],
    ancestors: AreaTable[]
  ) => {
    areaTables.forEach((areaTable) => {
      const { children, crackListItems } = areaTable;

      if (children.length === 0) {
        let headline: React.ReactNode = null;

        const titleAreas = [...ancestors, areaTable];
        titleAreas.forEach((a) => {
          const text = a.title;

          if (a.level === 1) {
            // don't repeat same headline over and over again
            if (text != lastHeadline) {
              lastHeadline = text;

              headline = <h3>{text}</h3>;
            }
          } else {
            headline = <h4>{text}</h4>;
          }
        });

        if (isFollowUpInspection) {
          const rootCrackListItems = crackListItems.filter(
            (c) => !c.crack?.mainCrackId
          );

          let content: React.ReactNode[] = [];

          rootCrackListItems.forEach((cli, idx) => {
            const originalCrack = crackListItems.find(
              isOriginalForCrackListItem(cli)
            );

            const followUpCrack = crackListItems.find(
              isFollowUpForCrackListItem(cli)
            );

            if (originalCrack || followUpCrack) {
              content.push(
                objectType === "street" ? (
                  <div key={idx} className="follow-up-crack-list-item-wrapper">
                    {originalCrack && (
                      <WebProtocolStreetCrackListItem
                        crackListItem={originalCrack}
                      />
                    )}
                    {followUpCrack && (
                      <WebProtocolStreetCrackListItem
                        crackListItem={followUpCrack}
                      />
                    )}
                  </div>
                ) : (
                  <div key={idx} className="follow-up-crack-list-item-wrapper">
                    {originalCrack && (
                      <WebProtocolCrackListItem
                        crackListItem={originalCrack}
                        crackType="original"
                      />
                    )}

                    {followUpCrack && (
                      <WebProtocolCrackListItem
                        crackListItem={followUpCrack}
                        crackType="followUp"
                      />
                    )}
                  </div>
                )
              );
            }
          });

          imageTables.push(
            <div
              key={`${areaTable.level}-${areaTable.title}`}
              className={classNames("crack-list-items follow-up", {
                street: objectType === "street",
              })}
            >
              {objectType !== "street" && headline}

              {content}
            </div>
          );
        } else {
          imageTables.push(
            <div
              key={`${areaTable.level}-${areaTable.title}`}
              className={classNames("crack-list-items", {
                street: objectType === "street",
              })}
            >
              {objectType !== "street" && headline}

              {crackListItems.map((cli, idx) => {
                return objectType === "street" ? (
                  <WebProtocolStreetCrackListItem
                    key={idx}
                    crackListItem={cli}
                  />
                ) : (
                  <WebProtocolCrackListItem key={idx} crackListItem={cli} />
                );
              })}
            </div>
          );
        }
      } else {
        createImageTables(children, [...ancestors, areaTable]);
      }
    });
  };

  createImageTables([areaTables], []);

  return imageTables;
};

import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "native-base";
import { useUsers } from "../../../hooks/useUsers";
import UserState from "../../user/UserState";

export default function UsersList() {
  const [users, isLoading] = useUsers();

  const navigate = useNavigate();

  const editUser = useCallback(
    (userId: string) => {
      navigate(`/admin/users/edit/${userId}`);
    },
    [navigate]
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>E-Mail</th>
          <th>Name</th>
          <th>Status</th>
          <th>Aktionen</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          const userData = user.data();
          const { id } = user;
          const { eMail, name, isActive } = userData;

          return (
            <tr key={id}>
              <td>{eMail}</td>
              <td>{name}</td>
              <td>
                <UserState isActive={isActive} />
              </td>
              <td>
                <Button variant="outline" onPress={() => editUser(id)}>
                  Bearbeiten
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

import { PredefinedArea } from "../hooks/useCreatePredefinedAreas";

export const predefinedAreas: PredefinedArea[] = [
  {
    id: "familyHome",
    label: "Einfamilienhaus",
    areas: [
      {
        title: "Aussen",
      },
      {
        title: "Innen",
        children: [
          {
            title: "Untergeschoss",
          },
          {
            title: "Erdgeschoss",
          },
          {
            title: "Obergeschoss",
          },
          {
            title: "Dachgeschoss",
          },
        ],
      },
    ],
  },
  {
    id: "familyHomeWithGardenHouse",
    label: "Einfamilienhaus mit Gartenhaus",
    areas: [
      {
        title: "Aussen",
      },
      {
        title: "Innen (Hauptgebäude)",
        children: [
          {
            title: "Untergeschoss",
          },
          {
            title: "Erdgeschoss",
          },
          {
            title: "Obergeschoss",
          },
          {
            title: "Dachgeschoss",
          },
        ],
      },
      {
        title: "Innen (Gartenhaus)",
        children: [
          {
            title: "Erdgeschoss",
          },
        ],
      },
    ],
  },
  {
    id: "apartmentHouse",
    label: "Mehrfamilienhaus",
    areas: [
      {
        title: "Aussen",
      },
      {
        title: "Innen",
        children: [
          {
            title: "Untergeschoss",
          },
          {
            title: "Erdgeschoss",
          },
          {
            title: "1. Obergeschoss",
          },
          {
            title: "2. Obergeschoss",
          },
          {
            title: "3. Obergeschoss",
          },
          {
            title: "Treppenhaus",
          },
        ],
      },
    ],
  },
  {
    id: "street",
    label: "Strasse",
    areas: [
      {
        title: "Strasse",
      },
    ],
  },
];

import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Button } from "native-base";
import { useCreateFollowUpInspection } from "../../hooks/useCreateFollowUpInspection.web";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function FollowUpInspection({ object }: Props) {
  const [createFollowUpInspection, isLoading] = useCreateFollowUpInspection();

  if (!object) {
    return null;
  }

  return (
    <Button
      onPress={() => createFollowUpInspection(object)}
      variant="solid"
      isLoading={isLoading}
    >
      Nachkontrolle erstellen
    </Button>
  );
}

import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AddIcon, Button } from "native-base";
import { useCreateProject } from "../../hooks/useCreateProject.web";

export default function CreateProjectButton() {
  const navigate = useNavigate();

  const createProject = useCreateProject();

  const onCreateProject = useCallback(async () => {
    const project = await createProject();

    navigate(`/admin/projects/${project.id}`);
  }, [createProject, navigate]);

  return (
    <Button
      onPress={onCreateProject}
      colorScheme="primary"
      startIcon={<AddIcon />}
      alignSelf="flex-end"
    >
      Auftrag hinzufügen
    </Button>
  );
}

import React from "react";
import { HStack, Text } from "native-base";
import StateIndicator from "../shared/StateIndicator";

type Props = {
  isActive?: boolean;
};

export default function UserState({ isActive }: Props) {
  return (
    <HStack space={2}>
      <StateIndicator stateColor={isActive ? "green" : "red"} />
      <Text>{isActive ? "Aktiv" : "Deaktiviert"}</Text>
    </HStack>
  );
}

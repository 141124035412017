import React from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import DeleteButton from "../../shared/DeleteButton";

type Props = {
  appointment: DocumentSnapshot<DocumentData>;
};

export default function DeleteAppointmentButton({ appointment }: Props) {
  return (
    <DeleteButton
      title="Termin löschen"
      description="Sind Sie sich sicher, dass Sie diesen Termin löschen wollen?"
      object={appointment as any}
      noMargin
    />
  );
}

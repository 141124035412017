import { StateColor } from "../hooks/useProjectState";

export type AppointmentState = "open" | "suggestion" | "confirmed";

export const appointmentStateMapping: { [Key in AppointmentState]: string } = {
  open: "Noch kein Termin",
  suggestion: "Terminvorschlag",
  confirmed: "Termin fix",
};

export const statusTypeOptions = [
  {
    label: appointmentStateMapping.open,
    value: "open",
  },
  {
    label: appointmentStateMapping.suggestion,
    value: "suggestion",
  },
  {
    label: appointmentStateMapping.confirmed,
    value: "confirmed",
  },
];

export const appointmentColorMapping: {
  [Key in AppointmentState]: StateColor;
} = {
  open: "red",
  suggestion: "yellow",
  confirmed: "green",
};

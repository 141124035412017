import { View } from "react-native";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { ImageSize } from "./MarkCracks";
import { crackMarkStyles } from "./CrackMark";

type Props = {
  crackMarking: FirebaseFirestoreTypes.QueryDocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  imageSize?: ImageSize;
};

export default function DisplayCrackMark({ crackMarking, imageSize }: Props) {
  const { x, y, size } = crackMarking.data();

  if (!imageSize) {
    return null;
  }

  const { width, height } = imageSize!;

  const absoluteSize = (size / 100) * width;

  const dynamicStyle = {
    left: -absoluteSize / 2,
    top: -absoluteSize / 2,
    height: absoluteSize,
    width: absoluteSize,
    transform: [
      { translateX: (x / 100) * width },
      { translateY: (y / 100) * height },
    ],
  };

  return <View style={[crackMarkStyles.crackMarkPosition, dynamicStyle]} />;
}

import { HStack, Spinner, Text, Icon } from "native-base";
import { Feather } from "@expo/vector-icons";

type Props = {
  photosUploaded: number;
  photosTotal: number;
};

export default function ObjectUploadState({
  photosUploaded,
  photosTotal,
}: Props) {
  if (photosTotal <= 0) {
    return null;
  }

  if (photosUploaded < photosTotal) {
    return (
      <HStack space={2}>
        <Spinner size="sm" color="gray" />

        <Text fontSize="xs" color="red.500">
          {`Synchronisiere Fotos (${photosUploaded} von ${photosTotal} Fotos)`}
          ...
        </Text>
      </HStack>
    );
  }
  return (
    <HStack space={2}>
      <Icon as={Feather} name="check" color="primary.400" />

      <Text fontSize="xs" color="primary.400">
        {`Alle Fotos synchronisiert (${photosTotal} Fotos)`}
      </Text>
    </HStack>
  );
}

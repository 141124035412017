import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import PhotoThumbnail from "../shared/PhotoThumbnail";
import { useOverviewPhotos } from "../../hooks/useOverviewPhotos";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
};

export default function ObjectThumbnail({ object }: Props) {
  const [overviewPhotos] = useOverviewPhotos(
    object.ref.parent.parent!.id,
    object.id
  );

  return <PhotoThumbnail photo={overviewPhotos[0]} />;
}

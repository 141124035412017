import { useCallback, useState } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { FormControl, Input, Icon, Modal, HStack, Button } from "native-base";
import { Feather } from "@expo/vector-icons";
import { SuggestionType } from "../../hooks/useSuggestions";
import { ObjectType } from "../../hooks/useObjectType";
import WebSuggestionModal from "../web/components/WebSuggestionModal";
import { NativeSyntheticEvent, TextInputFocusEventData } from "react-native";

type Props = {
  fieldPath: string;
  suggestionType: SuggestionType;
  label?: string;
  doc: DocumentSnapshot<DocumentData>;
  updateDoc: (data: any) => Promise<void>;
  level?: number;
  objectType: ObjectType | null;
  rootAreaType?: string;
  isDisabled?: boolean;
};

export default function SuggestionFormInput({
  fieldPath,
  suggestionType,
  label,
  doc,
  updateDoc,
  level,
  objectType,
  rootAreaType,
  isDisabled,
}: Props) {
  // TODO: Change text field to be controlled (via State)

  const [isModalShown, setModalShown] = useState(false);

  const openModal = useCallback(() => {
    console.log("Open modal is called");
    setModalShown(true);
  }, [setModalShown]);

  const onClose = useCallback(() => {
    setModalShown(false);
  }, [setModalShown]);

  const defaultValue = doc.get(fieldPath);

  const changeValue = useCallback(
    (newValue: string | undefined) => {
      updateDoc({ [fieldPath]: newValue });
    },
    [updateDoc, fieldPath]
  );

  const onBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      changeValue(e.nativeEvent.text);
    },
    [changeValue]
  );

  const onSelect = useCallback((suggestion?: string) => {
    changeValue(suggestion);
  }, []);

  return (
    <FormControl mb="1">
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <HStack>
        <Input
          defaultValue={defaultValue == null ? undefined : String(defaultValue)}
          onBlur={onBlur}
          bg="white"
          // Icon as rightElement only works with 3.4.21 and later, see https://github.com/GeekyAnts/NativeBase/issues/4720
          // rightElement={}
          flexGrow={1}
          isDisabled={isDisabled}
        />

        {!isDisabled && (
          <Button onPress={openModal} variant="outline" ml={1}>
            <Icon
              as={Feather}
              name="menu"
              onPress={openModal}
              color="primary.400"
            />
          </Button>
        )}
      </HStack>

      <Modal isOpen={isModalShown} onClose={onClose}>
        {isModalShown && (
          <WebSuggestionModal
            title={label ?? ""}
            suggestionType={suggestionType}
            onSelect={onSelect}
            level={level}
            objectType={objectType}
            rootAreaType={rootAreaType}
            onClose={onClose}
          />
        )}
      </Modal>
    </FormControl>
  );
}

export const getFilenameFromUrl = (url: string | undefined) => {
  if (!url) {
    return "";
  }

  try {
    const { pathname } = new URL(url ?? "");

    const path = decodeURIComponent(pathname);

    return path.split("/").pop() ?? "";
  } catch (e) {}

  return "";
};

import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { HStack } from "native-base";
import DeleteButton from "../shared/DeleteButton.web";
import ObjectWebAccessSendMail from "./ObjectWebAccessSendMail";

type Props = {
  webAccessId: string;
  webAccess: DocumentSnapshot<DocumentData>;
};

export default function ObjectWebAccessItem({ webAccessId, webAccess }: Props) {
  const { eMail, code } = webAccess.data() || {};

  return (
    <tr>
      <td>{eMail}</td>
      <td>{code}</td>
      <td>
        <HStack space={4}>
          <ObjectWebAccessSendMail
            webAccess={webAccess as any}
            webAccessId={webAccessId}
          />

          <DeleteButton
            title="Zugang entfernen"
            description="Sind Sie sich sicher, dass Sie diesen Zugang entfernen wollen?"
            object={webAccess as any}
            noMargin
            withIcon
          />
        </HStack>
      </td>
    </tr>
  );
}

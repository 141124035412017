import React from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { Button, Stack } from "native-base";
import { usePredefinedAreas } from "../../../hooks/useCreatePredefinedAreas";
import Subsection from "../../typo/Subsection";

type Props = {
  object: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>;
  updateObject: (data: any) => Promise<void>;
};

export default function PrefillAreas({ object }: Props) {
  const [predefinedAreas, createAreas] = usePredefinedAreas(
    object.ref.parent.parent!.id,
    object.id
  );

  return (
    <Stack space={4}>
      <Subsection title="Bereiche via Vorauswahl erstellen" />

      <Stack space={2}>
        {predefinedAreas.map(({ id, label }) => (
          <Button
            key={id}
            variant="outline"
            onPress={() => {
              createAreas(id);
            }}
          >
            {label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}

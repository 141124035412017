import React, { useCallback, useState } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { Button, Stack, useToast } from "native-base";
import Constants from "expo-constants";
import { useUserIDToken } from "../../../hooks/useUserIDToken.web";
import Subsection from "../../typo/Subsection";
import UserState from "../../user/UserState";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

type Props = {
  user: DocumentSnapshot<DocumentData>;
};

export default function ChangeStatus({ user }: Props) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const getUserIDToken = useUserIDToken();

  const userData = user.data();

  if (!userData) {
    return null;
  }

  const { isActive, eMail } = userData;

  const changeUserState = useCallback(
    async (newState: boolean) => {
      setLoading(true);

      const authToken = await getUserIDToken();

      try {
        const response = await fetch(
          `${BACKEND_API_BASE_URL}/users/changeState`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              authToken,
              eMail,
              newState,
            }),
          }
        );

        const json = await response.json();

        const { success, error } = json;

        if (success) {
          toast.show({
            placement: "top",
            title: newState
              ? "Der Benutzer wurde erfolgreich aktiviert."
              : "Der Benutzer wurde erfolgreich deaktiviert.",
          });
        } else {
          if (error) {
            toast.show({
              placement: "top",
              title: error,
            });
          } else {
            throw new Error(JSON.stringify(json));
          }
        }
      } catch (e) {
        console.error(e);

        toast.show({
          placement: "top",
          title:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
        });
      }

      setLoading(false);
    },
    [toast, setLoading]
  );

  return (
    <Stack space={4}>
      <Subsection title="Status ändern" />

      <UserState isActive={isActive} />

      <Button isLoading={isLoading} onPress={() => changeUserState(!isActive)}>
        {isActive ? "Benutzer deaktivieren" : "Benutzer aktivieren"}
      </Button>
    </Stack>
  );
}

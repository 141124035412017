import { useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../App.web";

export const useUpdateFirebaseObject = (docPath: string) => {
  return useCallback(
    async (data: any) => {
      const docRef = doc(firestore, docPath);

      return await updateDoc(docRef, data);
    },
    [docPath]
  );
};

import { Text } from "native-base";
import React from "react";
import {
  StateColor,
  stateColorMapping,
} from "../../../../hooks/useProjectState";

type Props = {
  count: number;
  total: number;
  color: StateColor | undefined;
};

export default function ProjectOverviewStateCount({
  count,
  total,
  color,
}: Props) {
  return (
    <Text
      color={color ? stateColorMapping[color] : "gray.50"}
      fontWeight="bold"
    >{`${count} / ${total}`}</Text>
  );
}

import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Text } from "native-base";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import ProjectOverviewStateCount from "./ProjectOverviewStateCount";
import { useCalculateProjectDetailState } from "../../../../hooks/useCalculateProjectDetailState";
import ProjectState from "../../../projects/ProjectState";

type Props = {
  project: QueryDocumentSnapshot<DocumentData>;
};

export default function ProjectOverviewItem({ project }: Props) {
  const { id } = project;
  const { projectNumber, title } = project.data();

  const navigate = useNavigate();

  const projectDetailState = useCalculateProjectDetailState(id);

  const goToDetail = useCallback(() => {
    navigate(["/admin/projects", id].join("/"));
  }, [navigate, id]);

  let projectDetailColumns = <td colSpan={8}>&nbsp;</td>;

  if (projectDetailState) {
    const {
      objectsCount,
      totalAppointmentsCount,
      confirmedAppointmentsCount,
      confirmedAppointmentsColor,
      protocolsCreatedCount,
      protocolsCreatedColor,
      sentToOwnerCount,
      sentToOwnerColor,
      returnedFromOwnerCount,
      returnedFromOwnerColor,
      sentToClientCount,
      sentToClientColor,
      returnedFromClientCount,
      returnedFromClientColor,
      sentFinalToOwnerCount,
      sentFinalToOwnerColor,
    } = projectDetailState;

    projectDetailColumns = (
      <>
        <td>
          <ProjectState project={project} />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={confirmedAppointmentsCount}
            total={totalAppointmentsCount}
            color={confirmedAppointmentsColor}
          />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={protocolsCreatedCount}
            total={objectsCount}
            color={protocolsCreatedColor}
          />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={sentToOwnerCount}
            total={objectsCount}
            color={sentToOwnerColor}
          />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={returnedFromOwnerCount}
            total={objectsCount}
            color={returnedFromOwnerColor}
          />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={sentToClientCount}
            total={objectsCount}
            color={sentToClientColor}
          />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={returnedFromClientCount}
            total={objectsCount}
            color={returnedFromClientColor}
          />
        </td>
        <td>
          <ProjectOverviewStateCount
            count={sentFinalToOwnerCount}
            total={objectsCount}
            color={sentFinalToOwnerColor}
          />
        </td>
      </>
    );
  }

  return (
    <tr onClick={goToDetail} style={{ cursor: "pointer" }}>
      <td>
        <Text fontWeight="bold">
          <Link to={id}>{projectNumber}</Link>
        </Text>
      </td>
      <td>
        <Text>
          <Link to={id}>{title}</Link>
        </Text>

        {false && projectDetailState && (
          <Box>
            {`${projectDetailState.objectsCount} ${
              projectDetailState.objectsCount === 1 ? "Objekt" : "Objekte"
            }`}
          </Box>
        )}
      </td>
      {projectDetailColumns}
    </tr>
  );
}

import React from "react";
import { Button, Icon, Spinner, Stack } from "native-base";
import { useParams, useSearchParams } from "react-router-dom";
import { Feather } from "@expo/vector-icons";
import Headline from "../typo/Headline";
import { useArea } from "../../hooks/useArea";
import AreaNotes from "../object/capture/AreaNotes";
import { useCracks } from "../../hooks/useCracks";
import { useObjectPhotos } from "../../hooks/useObjectPhotos";
import CrackList from "../object/capture/CrackList";
import SuggestionFormInput from "../form/SuggestionFormInput";
import DeleteAreaButton from "../object/capture/DeleteAreaButton";
import { useCreateCrack } from "../../hooks/useCreateCrack.web";
import { useObject } from "../../hooks/useObject";
import { isFollowUpInspection } from "../../helper/object-helper";

export default function AreaDetails() {
  const { projectId, objectId, areaId } = useParams();
  const [searchParams] = useSearchParams();

  const rootAreaType = searchParams.get("rootAreaType") ?? undefined;
  const levelAsString = searchParams.get("level") ?? undefined;
  const level = levelAsString != null ? Number(levelAsString) : undefined;
  const canAddCracks = searchParams.get("canAdd") === "true";

  const { object } = useObject(projectId!, objectId!);
  const isFollowUp = isFollowUpInspection(object);

  const { area, updateArea, isLoading } = useArea(
    projectId!,
    objectId!,
    areaId!
  );
  const [, cracksForArea, allCracksForArea] = useCracks(projectId!, objectId!);
  const { photosByCrackId } = useObjectPhotos(projectId!, objectId!);

  const createCrack = useCreateCrack(projectId!, objectId!, areaId!);

  if (!projectId || !objectId || !areaId) {
    return null;
  }

  return (
    <Stack px={5} py={6}>
      <Headline title="Bereich" />

      {isLoading ? (
        <Spinner />
      ) : (
        <Stack space={8}>
          <SuggestionFormInput
            label="Bereich"
            fieldPath="title"
            suggestionType="area"
            doc={area as any}
            updateDoc={updateArea as any}
            level={level}
            rootAreaType={rootAreaType}
          />

          {canAddCracks && (
            <>
              <Button
                alignSelf="flex-start"
                leftIcon={<Icon as={Feather} name="plus" color="white" />}
                onPress={() => createCrack()}
              >
                Riss hinzufügen
              </Button>

              <CrackList
                cracks={cracksForArea.get((area as any).id)}
                allCracks={allCracksForArea.get((area as any).id)}
                photosByCrackId={photosByCrackId}
                projectId={projectId}
                objectId={objectId}
                areaId={areaId}
                rootAreaType={rootAreaType}
                isFollowUpInspection={isFollowUp}
              />

              <AreaNotes area={area as any} updateArea={updateArea as any} />
            </>
          )}

          <DeleteAreaButton area={area as any} />
        </Stack>
      )}
    </Stack>
  );
}

import { useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../App.web";

export const useGetFirebaseObject = (docPath: string) => {
  return useCallback(async () => {
    const docRef = doc(firestore, docPath);

    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data();
  }, [docPath]);
};

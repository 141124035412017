import React, { useCallback } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { FormControl, HStack, Icon } from "native-base";
import { Feather } from "@expo/vector-icons";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import de from "date-fns/locale/de";
registerLocale("de", de);

type Props = {
  fieldPath: string;
  dateTime?: boolean;
  label?: string;
  doc: DocumentSnapshot<DocumentData>;
  updateDoc: (data: any) => Promise<void>;
};

export default function DateFormInput({
  fieldPath,
  dateTime,
  label,
  doc,
  updateDoc,
}: Props) {
  const handleConfirm = useCallback(
    (selectedDate: Date | null) => {
      updateDoc({ [fieldPath]: selectedDate });
    },
    [updateDoc, fieldPath],
  );

  const resetDate = useCallback(() => {
    handleConfirm(null);
  }, [handleConfirm]);

  const defaultValue = doc.get(fieldPath);

  const valueAsDate = defaultValue?.toDate();

  return (
    <FormControl mb="1" zIndex={1} alignSelf="flex-start">
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <HStack space={2} alignItems="center">
        <DatePicker
          selected={valueAsDate}
          onChange={handleConfirm}
          placeholderText="Datum wählen"
          locale="de"
          showTimeSelect={dateTime}
          dateFormat={dateTime ? "Pp" : "P"}
          timeIntervals={15}
          popperClassName="vermflex-popover"
        />

        {defaultValue && (
          <Icon
            as={Feather}
            name="trash"
            onPress={resetDate}
            color="gray.500"
          />
        )}
      </HStack>
    </FormControl>
  );
}

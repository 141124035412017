import { useCallback, useState } from "react";
import Constants from "expo-constants";
import { saveAs } from "file-saver";

const BACKEND_API_BASE_URL = Constants.expoConfig!.extra!.backendAPIBaseURL;

const filenameMapping = {
  appointment: "Terminbrief.docx",
  retour: "Retourbrief.docx",
  cover: "Begleitschreiben.docx",
  projectCover: "Begleitschreiben - Auftraggeber.docx",
  finalCover: "Begleitschreiben - Schlussexamplar.docx",
};

export const useCreateLetter = (
  letterType:
    | "appointment"
    | "retour"
    | "cover"
    | "projectCover"
    | "finalCover",
) => {
  const [isLoading, setIsLoading] = useState(false);

  const createLetter = useCallback(
    (payload: any) => {
      const createLetter = async () => {
        setIsLoading(true);

        const response = await fetch(
          `${BACKEND_API_BASE_URL}/letter/${letterType}`,
          {
            method: "POST",
            headers: {
              Accept: "application/octet-stream",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          },
        );

        const blob = await response.blob();

        saveAs(blob, filenameMapping[letterType]);
        setIsLoading(false);
      };

      createLetter();
    },
    [setIsLoading],
  );

  return [createLetter, isLoading] as const;
};

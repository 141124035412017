import React, { useCallback } from "react";
import { Button } from "native-base";
import { useCreateLetter } from "../../../hooks/useCreateLetter";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useAuthorFields } from "../../../hooks/useAuthorFields";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { DEFAULT_SALUTATION } from "../../web/components/SalutationField";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function CreateCoverLetterButton({ object }: Props) {
  const [user] = useCurrentUser();
  const [createLetter, isLoading] = useCreateLetter("cover");

  const author = useAuthorFields(user);

  const address = object.get("owner") ?? "";
  const salutation = object.get("salutation") ?? DEFAULT_SALUTATION;
  const objectAddress = object.get("address") ?? "";

  const createCoverLetter = useCallback(() => {
    const payload = {
      address,
      salutation,
      author,
      objectAddress,
    };

    createLetter(payload);
  }, [createLetter, address, salutation, author, objectAddress]);

  return (
    <Button onPress={createCoverLetter} variant="solid" isLoading={isLoading}>
      Begleitschreiben Eigentümer
    </Button>
  );
}

import { isEmpty, sortBy } from "lodash";
import { useMemo } from "react";
import { useFirestoreCollection } from "./useFirestoreCollection";

export type SuggestionType = "area" | "crack" | "damage" | "position" | "width";

export const useSuggestions = (
  suggestionType: SuggestionType,
  searchQuery: string,
  level?: number,
  rootAreaType?: string
) => {
  const [suggestions, isLoading] = useFirestoreCollection(
    `${suggestionType}Suggestions`
  );

  const filteredSuggestions = useMemo(() => {
    let filtered = [...(suggestions ?? [])];

    if (!isEmpty(searchQuery)) {
      filtered = filtered.filter((s) => {
        const title = s.get("title");

        if (title == null) {
          return false;
        }

        return String(title).toLowerCase().includes(searchQuery.toLowerCase());
      });
    }

    if (level) {
      filtered = filtered.filter((s) => {
        const levels = s.get<number[] | null>("levels");

        // no level given means that the item should appear for all levels
        if (isEmpty(levels)) {
          return true;
        }

        return levels?.includes(level);
      });
    }

    if (rootAreaType) {
      filtered = filtered.filter((s) => {
        const availableInAreas = s.get<string[] | null>("availableInAreas");

        if (isEmpty(availableInAreas)) {
          return true;
        }

        return availableInAreas?.includes(rootAreaType);
      });
    }

    return filtered;
  }, [suggestions, searchQuery, level]);

  const filteredAndSortedSuggestions = sortBy(filteredSuggestions, [
    (s) => s.get("order"),
  ]);

  return [filteredAndSortedSuggestions, isLoading] as const;
};

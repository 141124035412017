import { useCallback } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { FormControl, TextArea } from "native-base";
import { NativeSyntheticEvent, TextInputFocusEventData } from "react-native";

type Props = {
  fieldPath: string;
  label?: string;
  doc: DocumentSnapshot<DocumentData>;
  updateDoc: (data: any) => Promise<void>;
};

export default function TextAreaFormInput({
  fieldPath,
  label,
  doc,
  updateDoc,
}: Props) {
  const defaultValue = doc.get(fieldPath);

  const onBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      updateDoc({ [fieldPath]: e.nativeEvent.text });
    },
    [updateDoc, fieldPath]
  );

  return (
    <FormControl mb="1">
      {label && <FormControl.Label>{label}</FormControl.Label>}

      <TextArea
        defaultValue={defaultValue == null ? undefined : String(defaultValue)}
        onBlur={onBlur}
        totalLines={6}
        autoCompleteType="off"
        bg="white"
        h="100px"
      />
    </FormControl>
  );
}

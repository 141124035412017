import React, { useCallback, useState } from "react";
import { Icon, Pressable, Spinner, Stack, Text } from "native-base";
import { Feather } from "@expo/vector-icons";
import PhotoButton from "../../shared/PhotoButton";
import PhotoThumbnailWithCrackMarks from "../../shared/PhotoThumbnailWithCrackMarks.web";
import { useCrackMarks } from "../../../hooks/useCrackMarks";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import {
  dragActiveStyle,
  useUploadPhotoToObject,
} from "../../../hooks/useUploadPhotoToObject";
import PhotoModal from "./PhotoModal";

type Props = {
  crack: DocumentSnapshot<DocumentData>;
  photosByCrackId: Map<string, QueryDocumentSnapshot<DocumentData>[]>;
  projectId: string;
  objectId: string;
  areaId: string;
  isDisabled?: boolean;
};

export default function CrackOverviewPhoto({
  crack,
  photosByCrackId,
  projectId,
  objectId,
  isDisabled,
}: Props) {
  const [isShowingModal, setShowModal] = useState(false);

  const onPress = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const onClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const [uploadPhoto, isUploading, dropzone] = useUploadPhotoToObject(
    "overviewPhoto",
    projectId,
    objectId,
    crack.id,
    1
  );

  const { getRootProps, isDragAccept } = dropzone;

  const { crackMarks } = useCrackMarks(projectId, objectId, crack.id);

  const data = crack.data();

  if (!data) {
    return null;
  }

  const photos = photosByCrackId.get(crack.id) ?? [];

  const overviewPhotos = photos.filter(
    (p) => p.get("photoType") === "overviewPhoto"
  );

  if (overviewPhotos.length <= 0) {
    return (
      <div {...getRootProps()} style={dragActiveStyle(isDragAccept)}>
        <PhotoButton onPress={uploadPhoto} noMargin>
          <Stack space={1} alignItems="center">
            {isUploading ? (
              <Spinner color="white" />
            ) : (
              <Icon as={Feather} name="plus" color="white" size="lg" />
            )}
            <Text color="white" fontSize="xs" textAlign="center">
              Übersichtsfoto
            </Text>
          </Stack>
        </PhotoButton>
      </div>
    );
  }

  const photo: any = overviewPhotos[0];

  return (
    <>
      <Pressable onPress={onPress}>
        <PhotoThumbnailWithCrackMarks photo={photo} crack={crack} />
      </Pressable>

      <PhotoModal
        objectName="Übersichtsfoto"
        crack={crack}
        crackMarks={crackMarks as any}
        photo={isShowingModal ? photo : undefined}
        onClose={onClose}
        isDisabled={isDisabled}
      />
    </>
  );
}

import React, { useCallback, useMemo, useState } from "react";
import { Box, Icon, Pressable, Spinner } from "native-base";
import { Feather } from "@expo/vector-icons";
import PhotoThumbnail from "../../shared/PhotoThumbnail";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import PhotoModal from "./PhotoModal";
import PhotoButton from "../../shared/PhotoButton";
import {
  dragActiveStyle,
  useUploadPhotoToObject,
} from "../../../hooks/useUploadPhotoToObject";

type Props = {
  crack: DocumentSnapshot<DocumentData>;
  detailPhotos: QueryDocumentSnapshot<DocumentData>[];
  projectId: string;
  objectId: string;
  areaId: string;
  isDisabled?: boolean;
};

export default function CrackDetailPhotosList({
  crack,
  detailPhotos,
  projectId,
  objectId,
  isDisabled,
}: Props) {
  const [showDetailPhoto, setShowDetailPhoto] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >(undefined);

  const onClose = useCallback(() => {
    setShowDetailPhoto(undefined);
  }, [setShowDetailPhoto]);

  const [uploadPhoto, isUploading, dropzone] = useUploadPhotoToObject(
    "detailPhoto",
    projectId,
    objectId,
    crack.id
  );

  const { getRootProps, isDragAccept } = dropzone;

  const data = crack.data();

  if (!data) {
    return null;
  }

  const items = useMemo(() => {
    return [
      ...detailPhotos.map((dp) => (
        <Pressable
          onPress={() => {
            setShowDetailPhoto(dp);
          }}
        >
          <PhotoThumbnail key={dp.id} photo={dp as any} />
        </Pressable>
      )),
      ...(isDisabled
        ? []
        : [
            <div {...getRootProps()} style={dragActiveStyle(isDragAccept)}>
              <PhotoButton onPress={uploadPhoto}>
                {isUploading ? (
                  <Spinner color="white" />
                ) : (
                  <Icon as={Feather} name="plus" color="white" size="xl" />
                )}
              </PhotoButton>
            </div>,
          ]),
    ];
  }, [detailPhotos, uploadPhoto, isDragAccept]);

  return (
    <>
      <Box my={2} mx={-2} flexDirection="row" flexWrap="wrap">
        {items.map((item, idx) => (
          <Box key={idx} w={200} h={150} m={2}>
            {item}
          </Box>
        ))}
      </Box>

      <PhotoModal
        objectName="Detailfoto"
        photo={showDetailPhoto}
        onClose={onClose}
        isDisabled={isDisabled}
      />
    </>
  );
}

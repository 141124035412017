import { useRef } from "react";
import { AlertDialog, Button } from "native-base";

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  onDelete?: () => void;
  onClose?: () => void;
};

export default function ConfirmDeletion({
  isOpen,
  title,
  description,
  onDelete,
  onClose,
}: Props) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />

        <AlertDialog.Header>{title}</AlertDialog.Header>

        <AlertDialog.Body>{description}</AlertDialog.Body>

        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button
              variant="unstyled"
              colorScheme="coolGray"
              onPress={onClose}
              ref={cancelRef}
            >
              Abbrechen
            </Button>

            <Button colorScheme="danger" onPress={onDelete}>
              Löschen
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}

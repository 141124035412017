import { useEffect } from "react";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { useCalculateProjectDetailState } from "./useCalculateProjectDetailState";
import { StateColor } from "./useProjectState";

type ProjectDetailState = {
  stateText: string;
  stateColor: StateColor | undefined;
};

export const useProjectDetailState = (
  project: FirebaseFirestoreTypes.DocumentSnapshot<FirebaseFirestoreTypes.DocumentData>,
  updateProject: (data: any) => Promise<void>
) => {
  const projectDetailState = useCalculateProjectDetailState(project.id);

  const savedProjectState = project.get("state");

  useEffect(() => {
    if (
      projectDetailState?.projectState &&
      projectDetailState.projectState !== savedProjectState
    ) {
      // change project state
      (async () => {
        await updateProject({ state: projectDetailState.projectState });
      })();
    }
  }, [projectDetailState?.projectState, savedProjectState, updateProject]);

  if (!projectDetailState) {
    return;
  }

  const {
    objectsCount,
    totalAppointmentsCount,
    confirmedAppointmentsCount,
    confirmedAppointmentsColor,
    protocolsCreatedCount,
    protocolsCreatedColor,
    sentToOwnerCount,
    sentToOwnerColor,
    returnedFromOwnerCount,
    returnedFromOwnerColor,
    sentToClientCount,
    sentToClientColor,
    returnedFromClientCount,
    returnedFromClientColor,
    sentFinalToOwnerCount,
    sentFinalToOwnerColor,
  } = projectDetailState;

  const states: ProjectDetailState[] = [];

  states.push({
    stateText: `Termine fix: ${confirmedAppointmentsCount}/${totalAppointmentsCount}`,
    stateColor: confirmedAppointmentsColor,
  });

  states.push({
    stateText: `Protokolle erstellt: ${protocolsCreatedCount}/${objectsCount}`,
    stateColor: protocolsCreatedColor,
  });

  states.push({
    stateText: `An Eigentümer verschickt: ${sentToOwnerCount}/${objectsCount}`,
    stateColor: sentToOwnerColor,
  });

  states.push({
    stateText: `Von Eigentümer zurück: ${returnedFromOwnerCount}/${objectsCount}`,
    stateColor: returnedFromOwnerColor,
  });

  states.push({
    stateText: `An Auftraggeber verschickt: ${sentToClientCount}/${objectsCount}`,
    stateColor: sentToClientColor,
  });

  states.push({
    stateText: `Von Auftraggeber zurück: ${returnedFromClientCount}/${objectsCount}`,
    stateColor: returnedFromClientColor,
  });

  states.push({
    stateText: `An Eigentümer gesendet: ${sentFinalToOwnerCount}/${objectsCount}`,
    stateColor: sentFinalToOwnerColor,
  });

  return states;
};
